import { InjectionToken } from '@angular/core';
import { IIntegrationService } from 'integrations/integration-base.service';
import { Observable } from 'rxjs';
import { IObsConfigModel, ObsStatus } from '../models/obs.models';

export const obsServiceToken = new InjectionToken<IObsService>('IOBSService');

export interface IObsService extends IIntegrationService<IObsConfigModel> {
	connect(): Observable<boolean>;
	disconnect(): void;
	setScene(scene: string): Observable<void>;
	hideItemsBeforeTransition(scene: string): Observable<void>;
	showItemsAfterTransition(scene: string): Observable<void>;
	watchStatus(): Observable<ObsStatus>;
}
