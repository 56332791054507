import { NgModule } from '@angular/core';
import { ProfileManager } from './services/profile.manager';
import { ProfileService } from './services/profile.service';

@NgModule({
	declarations: [],
	providers: [
		ProfileManager,
		ProfileService
	]
})
export class ProfileModule { }
