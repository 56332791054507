import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IBidDataModel, IChallengeDataModel, IDonationDataModel, IMilestoneDataModel } from 'core/models/data.models';
import { ApiBaseService } from 'core/services/api/api-base.service';

import { ISendBidsRequestModel, ISendChallengesRequestModel, ISendDonationRequestModel, ISendMilestonesRequestModel } from '../models/request.models';

@Injectable()
export class DonationsService extends ApiBaseService {
	constructor(http: HttpClient) {
		super(http);
		this.baseUrl = 'donations';
	}

	public sendDonation(channel: string, profile: string, sourceId: string, donation: IDonationDataModel): Observable<void> {
		const request = {
			channel: channel,
			profile: profile,
			donations: [donation]
		} as ISendDonationRequestModel;

		return this.put<void>(`/donations`, request);
	}

	public sendMilestones(channel: string, profile: string, milestones: IMilestoneDataModel[] = []): Observable<void> {
		const request = {
			channel: channel,
			profile: profile,
			milestones: milestones
		} as ISendMilestonesRequestModel;

		return this.put<void>(`/milestones`, request);
	}

	public sendBids(channel: string, profile: string, bids: IBidDataModel[]): Observable<void> {
		const request = {
			channel: channel,
			profile: profile,
			bids: bids
		} as ISendBidsRequestModel;

		return this.put<void>(`/bids`, request);
	}

	public sendChallenges(channel: string, profile: string, challenges: IChallengeDataModel[]): Observable<void> {
		const request = {
			channel: channel,
			profile: profile,
			challenges: challenges
		} as ISendChallengesRequestModel;

		return this.put<void>(`/challenges`, request);
	}
}
