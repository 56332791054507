import { Component, Input } from '@angular/core';

import { IPersonInfoModel } from '../../models/person.models';

import { SOCIAL_CHANNEL_ENUM } from '../../../shared/utility/social-channels';

@Component({
	selector: 'stream-player-list',
	templateUrl: './player-list.component.html',
	styleUrls: ['./player-list.component.scss']
})
export class PlayerListComponent {
	public isModified: boolean = false;

	@Input()
	public players: IPersonInfoModel[] = [];

	public clearPlayer(index: number): void {
		if (!this.players[index]) { return; }

		this.players[index]._delete = this.players[index]._delete === true ? false : true;
	}
}
