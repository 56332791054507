import { Directive } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ObservableComponentBase } from './observable-component.base';

import { AppStateService } from 'core/services/state/app-state.service';
import { IStorageService } from 'core/services/storage/storage.service';

import { IChannelModel } from 'channel/models/channel.models';
import { IProfileModel } from 'channel/models/profile.models';

@Directive()
export abstract class ProfileComponentBase extends ObservableComponentBase {
	public channel: IChannelModel;
	public profile: IProfileModel;

	protected profileStream: Observable<IProfileModel>;

	constructor(
		protected appState: AppStateService,
		protected storage: IStorageService
	) {
		super();
	}

	protected whenProfileSettled: () => void = null;

	protected initObservables(): Observable<any>[] {
		return [
			this.profileStream = this.appState.watchActiveProfile()
		];
	}

	protected initSubscriptions(): Subscription[] {
		return [
			this.appState.watchChannels()
				.subscribe((result) => {
					if (!result || !result.length) { return; }
					// TODO: Don't assume first channel
					this.channel = result[0];
				}),
			this.profileStream
				.subscribe((result) => {
					this.profile = result;

					if (result && this.whenProfileSettled) {
						setTimeout(() => { this.whenProfileSettled(); }, 0);
					}
				})
		];
	}
}
