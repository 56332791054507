import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { createSelector } from 'reselect';
import { Observable } from 'rxjs';

import { IBidDataModel, IChallengeDataModel, IDonationDataModel, IDonationTotalDataModel, IMilestoneDataModel, IScheduleDataModel } from 'core/models/data.models';
import { IHash } from 'core/models/hash.models';
import { DATA_STATE_ACTIONS } from 'core/state/data-state.actions';
import { IDataState } from 'core/state/data-state.model';
import { getDataState, IRootState } from 'core/state/root-state.model';
import { ISubstateAction } from 'core/state/state-actions';
import { StateBaseService } from './state-base.service';

import { IPersonInfoModel } from 'people/models/person.models';

const getDonations = createSelector(getDataState, (state: IDataState): IHash<IDonationDataModel[]> => state.donations);
const getDonationTotals = createSelector(getDataState, (state: IDataState): IHash<IDonationTotalDataModel> => state.donationTotals);
const getMilestones = createSelector(getDataState, (state: IDataState): IMilestoneDataModel[] => state.milestones);
const getBids = createSelector(getDataState, (state: IDataState): IBidDataModel[] => state.bids);
const getChallenges = createSelector(getDataState, (state: IDataState): IChallengeDataModel[] => state.challenges);

const getIncomingSchedule = createSelector(getDataState, (state: IDataState): IHash<IScheduleDataModel[]> => state.incomingSchedule);
const getOutgoingSchedule = createSelector(getDataState, (state: IDataState): IScheduleDataModel[] => state.outgoingSchedule);

const getPeople = createSelector(getDataState, (state: IDataState): IPersonInfoModel[] => state.people);
const getCurrentPlayers = createSelector(getDataState, (state: IDataState): IPersonInfoModel[] => state.currentPlayers);

@Injectable()
export class DataStateService extends StateBaseService<ISubstateAction<DATA_STATE_ACTIONS>> {
	constructor(state: NgRedux<IRootState>) {
		super(state);
	}

	public storeDonations = (donations: IDonationDataModel[], sourceId: string): void =>
		this.dispatch({
			type: DATA_STATE_ACTIONS.STORE_DONATIONS,
			key: sourceId,
			payload: donations
		})

	public watchDonations = (): Observable<IHash<IDonationDataModel[]>> => this.select(getDonations);

	public storeDonationTotal = (total: IDonationTotalDataModel, sourceId: string): void =>
		this.dispatch({
			type: DATA_STATE_ACTIONS.STORE_DONATION_TOTAL,
			key: sourceId,
			payload: total
		})

	public watchDonationTotals = (): Observable<IHash<IDonationTotalDataModel>> => this.select(getDonationTotals);

	public storeDonationChange = (donation: IDonationDataModel, sourceId: string): void => {
		this.dispatch({
			type: DATA_STATE_ACTIONS.STORE_DONATION_CHANGE,
			key: sourceId,
			payload: donation
		});
	}

	public storeMilestones = (milestones: IMilestoneDataModel[]): void =>
		this.dispatch({
			type: DATA_STATE_ACTIONS.STORE_MILESTONES,
			payload: milestones
		})

	public watchMilestones = (): Observable<IMilestoneDataModel[]> => this.select(getMilestones);

	public storeBids = (bids: IBidDataModel[]): void =>
		this.dispatch({
			type: DATA_STATE_ACTIONS.STORE_BIDS,
			payload: bids
		})

	public watchBids = (): Observable<IBidDataModel[]> => this.select(getBids);

	public storeChallenges = (challenges: IChallengeDataModel[]): void =>
		this.dispatch({
			type: DATA_STATE_ACTIONS.STORE_CHALLENGES,
			payload: challenges
		})

	public watchChallenges = (): Observable<IChallengeDataModel[]> => this.select(getChallenges);

	public storeIncomingSchedule = (schedule: IScheduleDataModel[], sourceId: string): void =>
		this.dispatch({
			type: DATA_STATE_ACTIONS.STORE_INCOMING_SCHEDULE,
			key: sourceId,
			payload: schedule
		})

	public watchIncomingSchedule = (): Observable<IHash<IScheduleDataModel[]>> => this.select(getIncomingSchedule);

	public storeOutgoingSchedule = (schedule: IScheduleDataModel[]): void =>
		this.dispatch({
			type: DATA_STATE_ACTIONS.STORE_OUTGOING_SCHEDULE,
			payload: schedule
		})

	public watchOutgoingSchedule = (): Observable<IScheduleDataModel[]> => this.select(getOutgoingSchedule);

	public storePeople = (people: IPersonInfoModel[]): void =>
		this.dispatch({
			type: DATA_STATE_ACTIONS.STORE_PEOPLE,
			payload: people
		})

	public watchPeople = (): Observable<IPersonInfoModel[]> => this.select(getPeople);

	public storeCurrentPlayers = (players: IPersonInfoModel[]): void =>
		this.dispatch({
			type: DATA_STATE_ACTIONS.STORE_CURRENT_PLAYERS,
			payload: players
		})

	public watchCurrentPlayers = (): Observable<IPersonInfoModel[]> => this.select(getCurrentPlayers);

	public clearDataState = (): void => this.dispatch({
		type: DATA_STATE_ACTIONS.CLEAR_STATE,
		payload: null
	})

	public watchDataState = (): Observable<IDataState> => this.select(getDataState);
}
