import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { BidsAdminComponent } from './components/bids-admin/bids-admin.component';
import { ChallengesAdminComponent } from './components/challenges-admin/challenges-admin.component';
import { DonationsAdminComponent } from './components/donations-admin/donations-admin.component';
import { DonationsListComponent } from './components/donations-list/donations-list.component';
import { MilestonesAdminComponent } from './components/milestones-admin/milestones-admin.component';

import { DonationsService } from './services/donations.service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
	],
	declarations: [
		BidsAdminComponent,
		ChallengesAdminComponent,
		DonationsAdminComponent,
		DonationsListComponent,
		MilestonesAdminComponent
	],
	providers: [
		DonationsService
	],
	exports: []
})
export class DonationModule { }
