import { InjectionToken } from '@angular/core';

export interface IStorageService {
	getValue: (key: string) => any;
	setValue: (key: string, value: any) => boolean;
	clearValue: (key: string) => boolean;
	reset: () => boolean;
}

// tslint:disable-next-line:variable-name
export const StorageServiceToken = new InjectionToken<IStorageService>('IStorageService');
