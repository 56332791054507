<table class="table table-bordered">
	<thead>
		<th>Alias</th>
		<th class="actions">Set as player</th>
	</thead>
	<tbody>
		<ng-template ngFor let-person [ngForOf]="people">
			<tr class="details">
				<td class="alias">
					{{ person.alias }} - {{ person.pronouns }}
				</td>
				<td class="actions">
					<div class="btn-group">
						<button class="btn btn-secondary btn-sm" (click)="setPlayer(person, 0)">1</button>
						<button class="btn btn-secondary btn-sm" (click)="setPlayer(person, 1)">2</button>
						<button class="btn btn-secondary btn-sm" (click)="setPlayer(person, 2)">3</button>
						<button class="btn btn-secondary btn-sm" (click)="setPlayer(person, 3)">4</button>
					</div>
				</td>
			</tr>

			<tr class="social">
				<td colspan="2">
					<div class="social-handles">
						<div class="social-handles-entry" *ngFor="let channel of person.channels">
							<stream-social-icon [type]="channel.type"></stream-social-icon>
							{{ channel.handle }}
						</div>
					</div>
				</td>
			</tr>
		</ng-template>
	</tbody>
</table>
