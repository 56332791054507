import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';

import { IHash } from 'core/models/hash.models';

import { TextElementComponent } from 'element/controls/text-element/text-element.component';
import { ELEMENT_TYPE_ENUM } from 'element/utility/element-type.enum';

@Injectable()
export class ElementManager {
	private _factories: IHash<ComponentFactory<any>> = {};

	constructor(private _factoryResolver: ComponentFactoryResolver) { }

	public createControlsComponent(elementType: ELEMENT_TYPE_ENUM, container: ViewContainerRef): ComponentRef<any> {
		const factory = this.getFactory(elementType);

		if (!factory) { return null; }

		return container.createComponent(factory);
	}

	// #region Internal

	private getFactory(elementType: ELEMENT_TYPE_ENUM): ComponentFactory<any> {
		if (!this._factories[elementType]) {
			switch (elementType) {
				case ELEMENT_TYPE_ENUM.Text:
					this._factories[elementType] = this._factoryResolver.resolveComponentFactory(TextElementComponent);
					break;
				default:
				// either not supported, or no extra controls for this element type
				// in both cases, no extra component needed / provided
			}
		}

		return this._factories[elementType] || null;
	}

	// #endregion
}
