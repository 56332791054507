<h1>Manage Frontends</h1>

<div class="row">
	<div class="col-md-12">
		<div>
			<button type="button" class="btn btn-primary" (click)="getFrontends()">
				Reload frontends
				<i class="fas fa-sync"></i>
			</button>
		</div>

		<stream-frontend-list [frontends]="frontends | async" (listAction)="doListAction($event)"></stream-frontend-list>
	</div>
</div>
