import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiBaseService } from 'core/services/api/api-base.service';
import { ICommandListRequestModel, ICommandListResponseModel, ICommandStartRequestModel, ICommandStopRequestModel } from '../models/command-api.models';
import { ICommandInfoModel } from '../models/command.models';

@Injectable()
export class CommandService extends ApiBaseService {
	constructor(http: HttpClient) {
		super(http);
		this.baseUrl = 'commands';
	}

	public getCommands(channel?: string, profile?: string): Observable<ICommandInfoModel[]> {
		return this
			.get<ICommandListResponseModel>('', {
				channel: channel,
				profile: profile
			} as ICommandListRequestModel)
			.pipe(
				map((x) => {
					return x.commands;
				})
			);
	}

	public start(channel: string, profile: string, id: string): Observable<void> {
		return this.put<void>('/start', {
			channel: channel,
			profile: profile,
			id: id
		} as ICommandStartRequestModel);
	}

	public stop(channel: string, profile: string, id: string): Observable<void> {
		return this.put<void>('/stop', {
			channel: channel,
			profile: profile,
			id: id
		} as ICommandStopRequestModel);
	}
}
