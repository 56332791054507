import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from 'core/services/api/api-base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IFrontendAuthorizeRequestModel, IFrontendListRequestModel, IFrontendListResponseModel, IFrontendSendStateRequestModel } from '../models/frontend-api.models';
import { IFrontendInfoModel } from '../models/frontend.models';

@Injectable()
export class FrontendService extends ApiBaseService {
	constructor(http: HttpClient) {
		super(http);
		this.baseUrl = 'frontends';
	}

	public getList(channel?: string, profile?: string): Observable<IFrontendInfoModel[]> {
		return this.get<IFrontendListResponseModel>('', {
			channel: channel,
			profile: profile
		} as IFrontendListRequestModel)
			.pipe(
				map((result) => {
					return result.frontends;
				})
			);
	}

	public authorize(id: string, channel: string, profile: string): Observable<void> {
		return this.put<void>('/authorize', {
			id: id,
			channel: channel,
			profile: profile
		} as IFrontendAuthorizeRequestModel);
	}

	public disconnect(id: string): Observable<void> {
		return this.put<void>('/disconnect', { id: id });
	}

	public sendState(channel: string, profile: string, state: string): Observable<void> {
		return this.put<void>('/state', {
			channel: channel,
			profile: profile,
			state: state
		} as IFrontendSendStateRequestModel);
	}
}
