import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ElementAdminComponent } from './components/element-admin/element-admin.component';
import { ElementContainerComponent } from './components/element-container/element-container.component';
import { ElementViewComponent } from './components/element-view/element-view.component';

import { ElementManager } from './services/element.manager';
import { ElementService } from './services/element.service';

import { TextElementComponent } from './controls/text-element/text-element.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule
	],
	declarations: [
		ElementAdminComponent,
		ElementContainerComponent,
		ElementViewComponent,

		TextElementComponent
	],
	providers: [
		ElementManager,
		ElementService
	]
})
export class ElementModule { }
