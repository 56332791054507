import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'shared/shared.module';

import { PeopleAdminComponent } from './components/people-admin/people-admin.component';
import { PeopleListComponent } from './components/people-list/people-list.component';
import { PlayerListComponent } from './components/player-list/player-list.component';

import { PersonService } from './services/person.service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,

		SharedModule
	],
	declarations: [
		PeopleAdminComponent,
		PeopleListComponent,
		PlayerListComponent
	],
	providers: [
		PersonService
	],
	exports: []
})
export class PeopleModule { }
