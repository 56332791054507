import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IBidDataModel } from 'core/models/data.models';

import { BIDS_ACTION_TYPE, IBidsAction } from 'donation/utility/bids-action';

@Component({
	selector: 'stream-bids-admin',
	templateUrl: './bids-admin.component.html'
})
export class BidsAdminComponent {
	@Input()
	public bids: IBidDataModel[] = [];

	@Output()
	public listAction = new EventEmitter<IBidsAction>();

	public removeGroup(group: IBidDataModel): void {
		this.listAction.emit({
			type: BIDS_ACTION_TYPE.RemoveGroup,
			item: group
		});
	}

	public addOption(group: IBidDataModel): void {
		this.listAction.emit({
			type: BIDS_ACTION_TYPE.AddOption,
			item: group
		});
	}

	public removeOption(group: IBidDataModel, optionId: string): void {
		this.listAction.emit({
			type: BIDS_ACTION_TYPE.RemoveOption,
			item: group,
			optionId: optionId
		});
	}
}
