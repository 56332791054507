import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';

import { ElementBaseComponent } from '../element-base/element-base.component';

import { IElementDataModel, IElementModel } from 'element/models/element.models';
import { ElementManager } from 'element/services/element.manager';
import { ELEMENT_TYPE_ICONS } from 'element/utility/element-type-icons.enum';

@Component({
	selector: 'stream-element-container',
	templateUrl: './element-container.component.html',
	styleUrls: ['./element-container.component.scss']
})

export class ElementContainerComponent implements OnInit {
	@ViewChild('controls', { read: ViewContainerRef, static: true })
	private _controlsContainer: ViewContainerRef;

	@Input()
	public model: IElementModel = { data: {} } as IElementModel;

	@Output()
	public update = new EventEmitter<IElementModel>();

	public hasControls: boolean = false;

	// TODO: actions - reset to orig value?

	constructor(private elementManager: ElementManager) { }

	public ngOnInit(): void {
		const controlsComponent = this.elementManager.createControlsComponent(this.model.type, this._controlsContainer);

		if (controlsComponent !== null) {
			(controlsComponent.instance as ElementBaseComponent<IElementDataModel>).model = this.model.data;
			this.hasControls = true;
		}
	}

	public getTypeIcon(): string {
		return `fas fa-${ELEMENT_TYPE_ICONS[this.model.type] || 'question'}`;
	}

	public setVisibility(isVisible: boolean) {
		this.model.data.isVisible = isVisible;
	}

	public commitUpdate(): void {
		this.update.emit(this.model);
	}
}
