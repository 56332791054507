<h1>Manage Schedule</h1>

<div class="row">
	<div class="col-md-6 incoming" [class.expand]="expandIncoming" *ngIf="hasSources">
		<div class="actions-container">
			<div class="left">
				<button type="button" class="btn btn-secondary" *ngFor="let sourceId of sourceIds" (click)="selectSource(sourceId)" [ngClass]="{'active': sourceId === selectedSourceId}">
					{{ sourceId }}
				</button>
			</div>

			<div class="right">
				<button class="btn btn-default" (click)="toggleExpandIncoming()" [title]="expandIncoming ? 'Collapse' : 'Expand'">
					Source
					<i class="fas" [ngClass]="{'fa-angle-double-right': !expandIncoming, 'fa-angle-double-left': expandIncoming}"></i>
				</button>
			</div>
		</div>

		<stream-schedule-incoming-list [schedule]="incomingSchedule | async" (listAction)="doIncomingListAction($event)"></stream-schedule-incoming-list>
	</div>

	<div class="col-md-6 outgoing" [ngClass]="{'col-md-6': hasSources, 'col-md-12': !hasSources, 'expand': expandOutgoing}">
		<div class="actions-container">
			<div class="left">
				<button class="btn btn-default" (click)="toggleExpandOutgoing()" [title]="expandOutgoing ? 'Collapse' : 'Expand'" *ngIf="hasSources">
					<i class="fas" [ngClass]="{'fa-angle-double-left': !expandOutgoing, 'fa-angle-double-right': expandOutgoing}"></i>
					Current
				</button>
			</div>

			<div class="right">
				<button class="btn btn-success" (click)="addGame()">
					Add game
					<i class="fas fa-plus"></i>
				</button>

				<button type="button" class="btn btn-primary" (click)="sendSchedule()" [disabled]="!isDirty">
					Update schedule
					<i class="fas fa-share"></i>
				</button>
			</div>
		</div>

		<stream-schedule-outgoing-list [schedule]="outgoingSchedule" (modifyAction)="markDirty()"></stream-schedule-outgoing-list>
	</div>
</div>
