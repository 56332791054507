import { Reducer } from 'redux';

import { DATA_STATE_ACTIONS } from './data-state.actions';
import { DEFAULT_DATA_STATE, generateBlankPlayer, IDataState } from './data-state.model';
import { ISubstateAction } from './state-actions';

import { IDonationDataModel, IIdentifiable } from 'core/models/data.models';
import { deepCopy, getObjectValue } from 'core/utility/data.utility';

type IMergeFunctor<T extends IIdentifiable> = (existing: T, incoming: T) => T;

const overwriteMerge = <T extends IIdentifiable>(x: T, y: T) => ({ ...x, ...y });

function mergeLists<T extends IIdentifiable>(existingList: T[], incomingList: T[], mergeFunctor: IMergeFunctor<T>): void {
	for (const incoming of incomingList) {
		const existingIndex = existingList.findIndex((x) => x.id === incoming.id);
		if (existingIndex > -1) {
			existingList[existingIndex] = mergeFunctor(existingList[existingIndex], incoming);
		}
		else {
			existingList.push(incoming);
		}
	}
}

export const dataReducer: Reducer<IDataState, ISubstateAction<DATA_STATE_ACTIONS>> = (state: IDataState = DEFAULT_DATA_STATE, action: ISubstateAction<DATA_STATE_ACTIONS>): IDataState => {
	switch (action.type) {
		case DATA_STATE_ACTIONS.STORE_INCOMING_SCHEDULE:
			const newIncoming = { ...state.incomingSchedule };
			newIncoming[action.key] = action.payload;
			return { ...state, incomingSchedule: newIncoming };

		case DATA_STATE_ACTIONS.STORE_OUTGOING_SCHEDULE:
			const newOutgoing = [...action.payload];

			return { ...state, outgoingSchedule: newOutgoing.filter((x) => !x._delete) };

		case DATA_STATE_ACTIONS.STORE_DONATIONS:
			return applyDonations(
				state,
				action.key,
				action.payload,
				// TODO: if it exists already, should it be ignored completely?
				(x, y) => {
					// update with any values that can't be manually modified
					return {
						...y,
						name: x.name,
						comment: x.comment,
						isDone: x.isDone,
						appliedTo: x.appliedTo
					};
				}
			);

		case DATA_STATE_ACTIONS.STORE_DONATION_TOTAL:
			const newDonationTotals = { ...state.donationTotals };
			newDonationTotals[action.key] = action.payload;

			return { ...state, donationTotals: newDonationTotals };

		// like above, but overwriting any / all existing data w/ explicit  changes
		case DATA_STATE_ACTIONS.STORE_DONATION_CHANGE:
			return applyDonations(
				state,
				action.key,
				[action.payload],
				overwriteMerge
			);

		case DATA_STATE_ACTIONS.STORE_MILESTONES:
			const newMilestones = [...state.milestones];
			mergeLists(newMilestones, action.payload, overwriteMerge);

			// trim out deleted milestones
			return { ...state, milestones: newMilestones.filter((x) => !x._delete) };

		case DATA_STATE_ACTIONS.STORE_BIDS:
			const newBids = [...state.bids];
			mergeLists(newBids, action.payload, overwriteMerge);

			// trim out deleted bid options
			newBids.forEach((x) => {
				x.options = x.options && x.options.filter((opt) => !opt._delete);
			});

			// trim out deleted bid groups
			return { ...state, bids: newBids.filter((x) => !x._delete) };

		case DATA_STATE_ACTIONS.STORE_CHALLENGES:
			const newChallenges = [...state.challenges];
			mergeLists(newChallenges, action.payload, overwriteMerge);

			// trim out deleted challenges
			return { ...state, challenges: newChallenges.filter((x) => !x._delete) };

		case DATA_STATE_ACTIONS.STORE_PEOPLE:
			return { ...state, people: action.payload };

		case DATA_STATE_ACTIONS.STORE_CURRENT_PLAYERS:
			const newPlayers = [...action.payload];
			for (let i = 0; i < newPlayers.length; i += 1) {
				if (newPlayers[i]._delete === true) {
					newPlayers[i] = generateBlankPlayer();
				}
			}

			return { ...state, currentPlayers: newPlayers };

		case DATA_STATE_ACTIONS.CLEAR_STATE:
			return deepCopy(DEFAULT_DATA_STATE);
	}

	return state;
};

function applyDonations(state: IDataState, key: string, incoming: IDonationDataModel[], mergeFunctor: IMergeFunctor<IDonationDataModel>): IDataState {
	const newDonations = { ...state.donations };
	const keyedDonations = [...getObjectValue<IDonationDataModel[]>(state.donations, key, [])];

	mergeLists(
		keyedDonations,
		incoming,
		mergeFunctor
	);

	newDonations[key] = keyedDonations;

	return { ...state, donations: newDonations };
}
