import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SocialAdminComponent } from './components/social-admin/social-admin.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
	],
	declarations: [
		SocialAdminComponent
	],
	providers: [],
	exports: []
})
export class SocialModule { }
