<table class="table table-bordered">
	<thead>
		<tr>
			<th class="actions">#</th>
			<th class="actions">Audio?</th>
			<th>Alias</th>
			<th>Social channels</th>
			<th class="actions"></th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let player of players; index as i" [class.deleted]="player._delete">
			<td class="flags">{{ i + 1 }}</td>
			<td class="flags">
				<input type="checkbox" [(ngModel)]="player.hasGameAudio">
			</td>
			<td class="social-aliases">
				<input type="text" class="form-control" [name]="'player' + i" [(ngModel)]="player.alias" />
			</td>
			<td class="social-handles">
				<div *ngFor="let channel of player.channels; index as j" class="social-handles-entry">
					<stream-social-icon [type]="channel.type" class="social-handles-entry-icon"></stream-social-icon>
					<input type="text" class="form-control social-handles-entry-input" [name]="'player' + i + 'channel' + j" [(ngModel)]="channel.handle" />
				</div>
			</td>
			<td class="actions">
				<button type="button" class="btn btn-danger" [disabled]="!player.alias" (click)="clearPlayer(i)" title="Remove player" *ngIf="!player._delete">
					<i class="fas fa-times"></i>
				</button>
				<button type="button" class="btn btn-default undelete" (click)="clearPlayer(i)" title="Undo remove" *ngIf="player._delete === true">
					<i class="fas fa-undo"></i>
				</button>
			</td>
		</tr>
	</tbody>
</table>
