import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiBaseService } from 'core/services/api/api-base.service';

import { IChannelModel } from '../models/channel.models';

// TODO: hasty implementation w/o api/service separation
@Injectable()
export class ChannelService extends ApiBaseService {
	constructor(
		http: HttpClient
	) {
		super(http);
		this.baseUrl = 'channels';
	}

	// TODO: load all channels for sys admin
	public getChannel(id: string): Observable<IChannelModel> {
		return this.get<IChannelModel>(`/${id}`);
	}
}
