import { Component, Inject } from '@angular/core';
import { take } from 'rxjs/operators';

import { ProfileComponentBase } from 'core/components/base/profile-component.base';

import { AppStateService } from 'core/services/state/app-state.service';
import { Observable } from 'rxjs';

import { IStorageService, StorageServiceToken } from 'core/services/storage/storage.service';

import { IAppState } from 'core/state/app-state.model';
import { IDataState } from 'core/state/data-state.model';
import { IUIState } from 'core/state/ui-state.models';

import { DataStateService } from 'core/services/state/data-state.service';
import { UIStateService } from 'core/services/state/ui-state.service';
import { FrontendService } from 'frontend/services/frontend.service';

@Component({
	selector: 'stream-data-management-admin',
	templateUrl: './data-management-admin.component.html',
	styleUrls: ['./data-management-admin.component.scss']
})
export class DataManagementAdminComponent extends ProfileComponentBase {
	public currentAppState: Observable<IAppState>;
	public currentDataState: Observable<IDataState>;
	public currentUIState: Observable<IUIState>;

	constructor(
		appState: AppStateService,
		private _dataState: DataStateService,
		private _uiState: UIStateService,
		@Inject(StorageServiceToken) storage: IStorageService,
		private _frontendService: FrontendService
	) {
		super(appState, storage);
	}

	protected initObservables(): Observable<any>[] {
		return [
			...super.initObservables(),
			this.currentAppState = this.appState.watchAppState(),
			this.currentDataState = this._dataState.watchDataState(),
			this.currentUIState = this._uiState.watchUIState()
		];
	}

	public clearDataState(): void {
		this._dataState.clearDataState();
	}

	public clearFrontendState(): void {
		this._frontendService.sendState(this.channel.id, this.profile.id, null)
			.pipe(
				take(1)
			)
			.subscribe();
	}
}
