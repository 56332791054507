import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FrontendAdminComponent } from './components/frontend-admin/frontend-admin.component';
import { FrontendListComponent } from './components/frontend-list/frontend-list.component';

import { FrontendService } from './services/frontend.service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule
	],
	declarations: [
		FrontendAdminComponent,
		FrontendListComponent
	],
	providers: [
		FrontendService
	],
	exports: []
})
export class FrontendModule { }
