import { IPersonInfoModel } from 'people/models/person.models';
import { SOCIAL_CHANNEL_ENUM } from 'shared/utility/social-channels';
import { IBidDataModel, IChallengeDataModel, IDonationDataModel, IDonationTotalDataModel, IMilestoneDataModel, IScheduleDataModel } from '../models/data.models';
import { IHash } from '../models/hash.models';

export interface IDataState {
	donations: IHash<IDonationDataModel[]>;
	donationTotals: IHash<IDonationTotalDataModel>;
	milestones: IMilestoneDataModel[];
	bids: IBidDataModel[];
	challenges: IChallengeDataModel[];

	incomingSchedule: IHash<IScheduleDataModel[]>;
	outgoingSchedule: IScheduleDataModel[];

	people: IPersonInfoModel[];
	currentPlayers: IPersonInfoModel[];
}

export function generateBlankPlayer() {
	return {
		alias: '',
		hasGameAudio: false,
		pronouns: '',
		channels: [{
			handle: '',
			type: SOCIAL_CHANNEL_ENUM.Twitch
		}]
	};
}

export const DEFAULT_DATA_STATE: IDataState = {
	donations: {},
	donationTotals: {},
	milestones: [],
	bids: [],
	challenges: [],

	incomingSchedule: {},
	outgoingSchedule: [],

	people: [],
	currentPlayers: [0, 1, 2, 3].map(() => generateBlankPlayer())
};
