import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ScheduleAdminComponent } from './components/schedule-admin/schedule-admin.component';
import { ScheduleIncomingListComponent } from './components/schedule-incoming-list/schedule-incoming-list.component';
import { ScheduleOutgoingListComponent } from './components/schedule-outgoing-list/schedule-outgoing-list.component';

import { ScheduleService } from './services/schedule.service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
	],
	declarations: [
		ScheduleAdminComponent,
		ScheduleIncomingListComponent,
		ScheduleOutgoingListComponent
	],
	providers: [
		ScheduleService
	],
	exports: []
})
export class ScheduleModule { }
