import { Reducer } from 'redux';

import { APP_STATE_ACTIONS } from './app-state.actions';
import { DEFAULT_APP_STATE, IAppState } from './app-state.model';
import { ISubstateAction } from './state-actions';

export const appReducer: Reducer<IAppState, ISubstateAction<APP_STATE_ACTIONS>> = (state: IAppState = DEFAULT_APP_STATE, action: ISubstateAction<APP_STATE_ACTIONS>): IAppState => {
	switch (action.type) {
		case APP_STATE_ACTIONS.STORE_CHANNELS:
			return { ...state, channels: action.payload };

		case APP_STATE_ACTIONS.STORE_FRONTENDS:
			return { ...state, frontends: action.payload };

		case APP_STATE_ACTIONS.STORE_ACTIVE_CHANNEL:
			return { ...state, activeChannel: action.payload };

		case APP_STATE_ACTIONS.STORE_ACTIVE_PROFILE:
			return { ...state, activeProfile: action.payload };

		case APP_STATE_ACTIONS.STORE_ACTIVE_VIEW:
			return { ...state, activeView: action.payload };
	}

	return state;
};
