import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { createSelector } from 'reselect';
import { Observable } from 'rxjs';

import { getUIState, IRootState } from 'core/state/root-state.model';
import { ISubstateAction } from 'core/state/state-actions';
import { UI_STATE_ACTIONS } from 'core/state/ui-state.actions';
import { IUIState } from 'core/state/ui-state.models';
import { StateBaseService } from './state-base.service';

const getIsChatExpanded = createSelector(getUIState, (state: IUIState): boolean => state.isChatExpanded);

@Injectable()
export class UIStateService extends StateBaseService<ISubstateAction<UI_STATE_ACTIONS>> {
	constructor(state: NgRedux<IRootState>) {
		super(state);
	}

	public storeIsChatExpanded = (isChatExpanded: boolean): void =>
		this.dispatch({
			type: UI_STATE_ACTIONS.STORE_CHAT_EXPAND,
			payload: isChatExpanded
		})

	public watchIsChatExpanded = (): Observable<boolean> =>
		this.select(getIsChatExpanded)

	public watchUIState = (): Observable<IUIState> => this.select(getUIState);
}
