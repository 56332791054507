import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { COMMAND_STATE, ICommandInfoModel } from '../../models/command.models';
import { COMMAND_LIST_ACTION, ICommandListAction } from '../../utility/command-list.action';

@Component({
	selector: 'stream-command-list',
	templateUrl: './command-list.component.html'
})
export class CommandListComponent {
	@Input()
	public commands: ICommandInfoModel[] = [];

	@Output()
	public listAction = new EventEmitter<ICommandListAction>();

	public getCommandState(command: ICommandInfoModel): string {
		// TODO: better way to return "enumeration" label
		switch (command.state) {
			case COMMAND_STATE.Complete:
				return 'Complete';
			case COMMAND_STATE.ErrorRetry:
				return 'Error - Auto-Retry';
			case COMMAND_STATE.ErrorFail:
				return 'Error - Failed';
			case COMMAND_STATE.Running:
				return 'Running';
			case COMMAND_STATE.Stopped:
				return 'Stopped';
			case COMMAND_STATE.Waiting:
				return 'Waiting';
			default:
				return 'Unknown';
		}
	}

	public getCommandFrequency(command: ICommandInfoModel): string {
		if (!command.interval) {
			return 'One-time / on-demand';
		}

		return `Every ${(command.interval / 1000).toFixed(2)} second(s)`;
	}

	public start(command: ICommandInfoModel): void {
		this.listAction.emit({
			action: COMMAND_LIST_ACTION.Start,
			command: command
		} as ICommandListAction);
	}

	public stop(command: ICommandInfoModel): void {
		this.listAction.emit({
			action: COMMAND_LIST_ACTION.Stop,
			command: command
		} as ICommandListAction);
	}

	public canStart(command: ICommandInfoModel): boolean {
		return (command.state === COMMAND_STATE.ErrorFail) ||
			(command.state === COMMAND_STATE.Stopped) ||
			(!command.interval && (command.state === COMMAND_STATE.Complete));
	}

	public canStop(command: ICommandInfoModel): boolean {
		return (command.interval > 0) &&
			((command.state === COMMAND_STATE.Complete)
				|| (command.state === COMMAND_STATE.Waiting)
				|| (command.state === COMMAND_STATE.ErrorRetry));
	}

	// TODO: more generic formatting
	public formatDate(value: Date): string {
		if (!value) {
			return 'Never';
		}

		return formatDate(value, 'dd MMM yyyy, HH:mm:ss', 'en-US', '-0500');
	}
}
