import { Injectable } from '@angular/core';

import { IHash } from '../../models/hash.models';

import { IWebsocketHandler, IWebsocketMessageModel, WEBSOCKET_MESSAGE_TYPE } from '../../models/websocket.models';
import { IWebsocketService } from './websocket.service';

import { AuthManagerService } from '../auth/auth-manager.service';

import { environment } from 'environment';

@Injectable()
export class NativeWebsocketService implements IWebsocketService {
	private _handlers: IHash<IWebsocketHandler<any>[]> = {};
	private _websocket: WebSocket = null;

	constructor(private _authManager: AuthManagerService) { }

	public connect(): void {
		const token = this._authManager.getToken();
		if (!token) {
			console.warn('could not connect to websocket');
			return;
		}

		this._websocket = new WebSocket(environment.websocketServerUrl);

		this._websocket.onopen = (event: Event): any => {
			// connect to websocket as an admin
			this.send({
				type: WEBSOCKET_MESSAGE_TYPE.AdminConnect,
				data: null,
				token: null
			} as IWebsocketMessageModel);

			this._websocket.onmessage = (message: MessageEvent): any => {
				// TODO: auto-parse incoming fields w/ date types: - https://trello.com/c/c4DINcxF
				// https://blog.mariusschulz.com/2016/04/28/deserializing-json-strings-as-javascript-date-objects
				const data = JSON.parse(message.data) as IWebsocketMessageModel;

				const handlers = this._handlers[data.type];

				if (handlers) {
					for (const handler of handlers) {
						handler(data);
					}
				}
			};
		};
	}

	public disconnect(): void {
		if (this._websocket) {
			this._websocket.close();
			this._websocket = undefined;
		}
	}

	public send(message: IWebsocketMessageModel): void {
		if (this._websocket.readyState !== 1) {
			// TODO: log error
			return;
		}

		message.token = this._authManager.getToken();

		try {
			this._websocket.send(JSON.stringify(message));
		} catch (ex) {
			// TODO: log error
		}
	}

	public handle(type: WEBSOCKET_MESSAGE_TYPE, handler: IWebsocketHandler<any>) {
		if (!this._handlers[type]) {
			this._handlers[type] = [];
		}

		this._handlers[type].push(handler);
	}

	public unhandle(type: WEBSOCKET_MESSAGE_TYPE): void {
		throw new Error('Not implemented');
	}
}
