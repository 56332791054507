import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppComponent } from 'app.component';
import { APP_ROUTES } from 'app.routes';

import { CoreModule } from 'core/core.module';

import { AdminModule } from 'admin/admin.module';
import { ChannelModule } from 'channel/channel.module';
import { CommandModule } from 'command/command.module';
import { DataManagementModule } from 'data-management/data-management.module';
import { DonationModule } from 'donation/donation.module';
import { ElementModule } from 'element/element.module';
import { FrontendModule } from 'frontend/frontend.module';
import { PeopleModule } from 'people/people.module';
import { ProfileModule } from 'profile/profile.module';
import { ScheduleModule } from 'schedule/schedule.module';
import { SharedModule } from 'shared/shared.module';
import { SocialModule } from 'social/social.module';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		RouterModule,
		RouterModule.forRoot(APP_ROUTES),

		CoreModule,
		SharedModule,

		AdminModule,
		ChannelModule,
		CommandModule,
		DataManagementModule,
		DonationModule,
		ElementModule,
		FrontendModule,
		PeopleModule,
		ProfileModule,
		ScheduleModule,
		SocialModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
