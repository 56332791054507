import { IHash } from 'core/models/hash.models';
import { ELEMENT_TYPE_ENUM } from './element-type.enum';

export const ELEMENT_TYPE_ICONS: IHash<string> = ((): IHash<string> => {
	const map: IHash<string> = {};

	map[ELEMENT_TYPE_ENUM.Bids] = 'comments-dollar';
	map[ELEMENT_TYPE_ENUM.Challenges] = 'bullseye';
	map[ELEMENT_TYPE_ENUM.CurrentGame] = 'gamepad';
	map[ELEMENT_TYPE_ENUM.DonationTotals] = 'dollar-sign';
	map[ELEMENT_TYPE_ENUM.Donations] = 'donate';
	map[ELEMENT_TYPE_ENUM.Images] = 'images';
	map[ELEMENT_TYPE_ENUM.Markup] = 'file-code';
	map[ELEMENT_TYPE_ENUM.Milestones] = 'flag-checkered';
	map[ELEMENT_TYPE_ENUM.Music] = 'music';
	map[ELEMENT_TYPE_ENUM.Players] = 'users';
	map[ELEMENT_TYPE_ENUM.Schedule] = 'calendar';
	map[ELEMENT_TYPE_ENUM.Text] = 'font';

	return map;
})();
