import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, Subject, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { getObjectValue } from 'core/utility/data.utility';
import { IntegrationBaseService } from 'integrations/integration-base.service';
import { IObsConfigModel, ObsStatus } from '../models/obs.models';
import { IObsService } from './obs.service';

import OBSWebSocket, { OBSRequestTypes, OBSResponseTypes } from 'obs-websocket-js';

@Injectable()
export class WebsocketObsService extends IntegrationBaseService<IObsConfigModel> implements IObsService {
	private _obs = new OBSWebSocket();
	private _status = new BehaviorSubject<ObsStatus>(ObsStatus.DISCONNECTED);
	private _transitionDuration: number = 0;

	protected executeLoad(config: IObsConfigModel): boolean {
		if (!getObjectValue(config, 'websocket.url')) { return false; }

		// general OBS connection event handling
		this._obs.on('ConnectionOpened', this.onConnect.bind(this));
		this._obs.on('ConnectionClosed', this.onDisconnect.bind(this));
		// this._obs.on('AuthenticationFailure', this.onError.bind(this));	// no longer exists in obs-websocket-js 5.x
		this._obs.on('ConnectionError', this.onError.bind(this));

		return true;
	}

	protected executeUnload(): void {
		this.disconnect();
	}

	public connect(): Observable<boolean> {
		const result = this._obs.connect(
			this.config.websocket.url,
			this.config.websocket.password);

		return from(result
			.catch((err) => {
				this.onError(err);
				return of(false)
					.pipe(
						take(1)
					);
			}))
			.pipe(
				take(1),
				map(() => {
					// get info on current transition
					this.sendCommand('GetCurrentSceneTransition')
						.subscribe((transition) => {
							this._transitionDuration = getObjectValue<number>(transition, 'transitionDuration', 0);
						});

					return true;
				})
			);
	}

	public disconnect(): void {
		this._obs.disconnect();
	}

	public setScene(scene: string): Observable<void> {
		return this.sendCommand('SetCurrentProgramScene', {
			sceneName: scene
		});
	}

	public hideItemsBeforeTransition(scene: string): Observable<void> {
		return this.setSceneItemVisibility(scene, false);
	}

	public showItemsAfterTransition(scene: string): Observable<void> {
		const subject = new Subject<void>();
		const delay: number =
			(this.config.transition.postDelay || 0) +
			(this._transitionDuration || 1000);

		setTimeout(() => {
			this.setSceneItemVisibility(scene, true)
				.subscribe(() => {
					subject.next();
				});
		}, delay);

		return subject;
	}

	public watchStatus(): Observable<ObsStatus> {
		return this._status;
	}

	// #region Internal

	private sendCommand<T extends keyof OBSRequestTypes>(type: T, data?: OBSRequestTypes[T]): Observable<OBSResponseTypes[T]> {
		return from(
			this._obs
				.call(type, data)
				.catch(this.onError) as Promise<OBSResponseTypes[T]>
		)
			.pipe(
				take(1)
			);
	}

	private onError(data: any): void {
		// console.warn(data);
		// TODO: log error, may not have interrupted connection
		// this.STATUS.next(ObsStatus.ERROR);
	}

	private onConnect(data: any): void {
		this._status.next(ObsStatus.CONNECTED);
	}

	private onDisconnect(data: any): void {
		this._status.next(ObsStatus.DISCONNECTED);
	}

	private setSceneItemVisibility(scene: string, isVisible: boolean): Observable<void> {
		const subject = new Subject<void>();

		const allChanges: Observable<void>[] = [];
		const items = getObjectValue<string[]>(this.config, `transition.hideItems.${scene}`, []);
		if (items.length > 0) {
			this.sendCommand<'GetSceneItemList'>('GetSceneItemList', {
				sceneName: scene
			})
				.subscribe((result) => {
					items.forEach((x) => {
						const sceneItem = result.sceneItems.find(item => item['sourceName'] === x);
						if (sceneItem) {
							const command = this.sendCommand('SetSceneItemEnabled', {
								sceneName: scene,
								sceneItemId: sceneItem['sceneItemId'] as number,
								sceneItemEnabled: isVisible
							});

							allChanges.push(command);
						}
					});

					zip(allChanges)
						.subscribe(() => {
							subject.next();
						});
				});

		}
		else {
			subject.next();
		}

		return subject;
	}

	// #endregion
}
