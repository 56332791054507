<div id="container">
	<header id="header">
		<stream-header></stream-header>
	</header>
	<div id="main">
		<aside>
			<router-outlet name="sidebar"></router-outlet>
		</aside>
		<main class="container-fluid">
			<router-outlet></router-outlet>
		</main>
	</div>

	<footer id="footer">
		<stream-footer></stream-footer>
	</footer>

	<stream-twitch-chat *ngIf="isChatEnabled" [class.expand]="showChat"></stream-twitch-chat>
</div>
