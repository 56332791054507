import { Component, Inject } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { combineLatest, delay, map, take } from 'rxjs/operators';

import { ProfileComponentBase } from 'core/components/base/profile-component.base';
import { IScheduleDataModel } from 'core/models/data.models';
import { AppStateService } from 'core/services/state/app-state.service';
import { IStorageService, StorageServiceToken } from 'core/services/storage/storage.service';

import { IScheduleUpdate } from '../../models/schedule.models';
import { ScheduleService } from '../../services/schedule.service';

import { DataStateService } from 'core/services/state/data-state.service';
import { IScheduleIncomingListAction, SCHEDULE_INCOMING_LIST_ACTION_TYPE } from '../../utility/incoming-schedule-list.action';

@Component({
	selector: 'stream-schedule-admin',
	templateUrl: './schedule-admin.component.html',
	styleUrls: ['./schedule-admin.component.scss']
})
export class ScheduleAdminComponent extends ProfileComponentBase {
	public sourceIds: string[] = null;
	public selectedSourceId: string = null;
	public hasSources: boolean = false;

	public expandIncoming: boolean = false;
	public expandOutgoing: boolean = false;
	public isDirty: boolean = false;

	public incomingSchedule: Observable<IScheduleUpdate>;
	public outgoingSchedule: IScheduleDataModel[] = [];

	private _scheduleSourceId = new Subject<string>();
	private _sourcesLoaded = new Subject<void>();

	constructor(
		appState: AppStateService,
		@Inject(StorageServiceToken) storage: IStorageService,
		private _dataState: DataStateService,
		private _scheduleService: ScheduleService
	) {
		super(appState, storage);
	}

	protected initObservables(): Observable<any>[] {
		return [
			...super.initObservables(),
			this.incomingSchedule = this._dataState.watchIncomingSchedule()
				.pipe(
					combineLatest(this._scheduleSourceId),
					map(([sources, selectedSourceId]) => {
						this.selectedSourceId = selectedSourceId;

						if (!this.selectedSourceId || !sources[this.selectedSourceId]) {
							return;
						}

						return {
							lastUpdate: moment.utc().toDate(),
							schedule: sources[this.selectedSourceId]
						} as IScheduleUpdate;
					})
				)
		];
	}

	protected initSubscriptions(): Subscription[] {
		return [
			...super.initSubscriptions(),

			// auto-show first source on initial sources load
			this._sourcesLoaded
				.pipe(
					delay(0),	// allow the detection cycle to complete
					take(1)
				)
				.subscribe(() => {
					const sourceId = this.sourceIds ? this.sourceIds[0] : null;

					if (sourceId) {
						this._scheduleSourceId.next(sourceId);
					}
				}),

			this._dataState.watchOutgoingSchedule()
				.subscribe((schedule) => {
					// TODO: revisit observable vs plain array: https://trello.com/c/Omt9Lx82
					this.outgoingSchedule = schedule;
				}),

			this._dataState.watchIncomingSchedule()
				.subscribe((sources) => {
					this.sourceIds = Object.keys(sources);
					this.hasSources = this.sourceIds.length > 0;

					// (re)load selected schedule source on data updates
					if (this.hasSources &&
						this.selectedSourceId) {
						this.selectSource(this.selectedSourceId);
					}

					// signal that sources are loaded
					this._sourcesLoaded.next();
				})
		];
	}

	public selectSource(sourceId: string): void {
		this._scheduleSourceId.next(sourceId);
	}

	public doIncomingListAction(action: IScheduleIncomingListAction): void {
		switch (action.type) {
			case SCHEDULE_INCOMING_LIST_ACTION_TYPE.Add:
				this.outgoingSchedule.push({ ...action.item });
				this.isDirty = true;
				break;
		}
	}

	public toggleExpandIncoming(): void {
		this.expandIncoming = !this.expandIncoming;
		this.expandOutgoing = (this.expandIncoming ? false : this.expandOutgoing);
	}

	public toggleExpandOutgoing(): void {
		this.expandOutgoing = !this.expandOutgoing;
		this.expandIncoming = (this.expandOutgoing ? false : this.expandIncoming);
	}

	public sendSchedule(): void {
		this._scheduleService.sendSchedule(this.channel.id, this.profile.id, this.outgoingSchedule)
			.pipe(
				take(1)
			)
			.subscribe(() => {
				this._dataState.storeOutgoingSchedule(this.outgoingSchedule);
				this.isDirty = false;
			});
	}

	public addGame(): void {
		this.outgoingSchedule.push({
			gameName: '',
			gamePlatform: '',
			gameReleaseYear: '',
			expectedDuration: '',
			runCategory: '',
			runners: ''
		} as IScheduleDataModel);

		this.isDirty = true;
	}

	public markDirty(): void {
		this.isDirty = true;
	}
}
