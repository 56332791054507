<h1>Manage Commands</h1>

<div class="row">
	<div class="col-md-12">
		<div>
			<button type="button" class="btn btn-primary" (click)="getCommands()">
				Reload commands
				<i class="fas fa-sync"></i>
			</button>
		</div>

		<stream-command-list [commands]="commands | async" (listAction)="doListAction($event)"></stream-command-list>
	</div>
</div>
