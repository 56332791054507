import { BehaviorSubject, Observable } from 'rxjs';

export interface IIntegrationService<T> {
	configStream: Observable<T>;

	load(config: T): boolean;
	unload(): void;
}

export abstract class IntegrationBaseService<T> implements IIntegrationService<T> {
	public configStream = new BehaviorSubject<T>(null);

	protected config: T = null as T;

	public load(config: T): boolean {
		if (!this.executeLoad(config)) { return false; }

		this.config = config;
		this.configStream.next(this.config);

		return true;
	}

	public unload(): void {
		this.executeUnload();

		this.config = null;
		this.configStream.next(this.config);
	}

	protected abstract executeLoad(config: T): boolean;

	protected abstract executeUnload(): void;
}
