import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { getObjectValue } from 'core/utility/data.utility';
import { IntegrationBaseService } from 'integrations/integration-base.service';
import { ITwitchChatUserModel, ITwitchConfigModel, TwitchChatStatus } from '../models/twitch.models';

const twitchChatWebsocketUrl: string = 'wss://irc-ws.chat.twitch.tv:443';

// TODO: extract and use standard websocket from core
@Injectable()
export class TwitchIrcService extends IntegrationBaseService<ITwitchConfigModel> {
	private _websocket: WebSocket = undefined;
	private _currentUser: ITwitchChatUserModel = {} as ITwitchChatUserModel;
	private _status = new BehaviorSubject<TwitchChatStatus>(TwitchChatStatus.DISCONNECTED);

	protected executeLoad(config: ITwitchConfigModel): boolean {
		if (!getObjectValue(config, 'channel')) { return false; }

		return true;
	}

	protected executeUnload(): void {
		this.disconnect();
	}

	public connect(): Observable<void> {
		const result = new Subject<void>();

		// already connected and connection ready - nothing to do
		if (this._websocket && this._websocket.readyState === 1) {
			this._status.next(TwitchChatStatus.CONNECTED);
			result.next();
			return result;
		}

		// not connected or connection closed / failed - (re)connect
		this.disconnect();
		this._websocket = new WebSocket(twitchChatWebsocketUrl);

		this._websocket.onopen = (event: Event): any => {
			this._websocket.onmessage = (message: MessageEvent): any => {
				const data = message.data as string;

				// TODO: log IRC responses?
				// console.log(`Twitch IRC response: ${data}`);

				// respond to pings
				if (data.startsWith('PING')) {
					this.send('PONG :twi.twitch.tv').subscribe();
					return;
				}
			};

			this._websocket.onerror = (errEvent: Event): any => {
				this._status.next(TwitchChatStatus.ERROR);
				// TODO: log error
				// console.error(errEvent);
			};

			this._status.next(TwitchChatStatus.CONNECTED);
			result.next();
		};

		return result.pipe(take(1));
	}

	public disconnect(): Observable<void> {
		if (this._websocket) {
			this._websocket.close();
			this._websocket = undefined;
		}

		this._status.next(TwitchChatStatus.DISCONNECTED);

		return of<void>().pipe(take(1));
	}

	public chatAs(id: string, message: string): Observable<void> {
		let ready: Observable<void>;

		if (this._currentUser.id !== id) {
			// change users
			const newUser = this.config.chatAs.find((x) => x.id === id);
			if (!newUser) {
				// TODO: user not found
				this._status.next(TwitchChatStatus.ERROR);
				return;
			}

			ready = this.setUser(newUser);
		}
		else {
			ready = new BehaviorSubject<void>(undefined);
		}

		return ready.pipe(
			switchMap(() => {
				const command = `PRIVMSG #${this.config.channel} :${message}`;

				return this.send(command);
			}),
			take(1)
		);
	}

	public getStatus(): Observable<TwitchChatStatus> {
		return this._status;
	}

	// #region Internal

	private setUser(user: ITwitchChatUserModel): Observable<void> {
		const result = new Subject<void>();

		this._currentUser = user;

		// must disconnect / reconnect socket
		this.disconnect();
		this.connect()
			.subscribe(() => {
				this.send(`PASS ${user.token}`).subscribe();
				this.send(`NICK ${user.id}`).subscribe();
				result.next();
			});

		return result;
	}

	private send(command: string): Observable<void> {
		const result = new Subject<void>();

		if (this._websocket.readyState !== 1) {
			// TODO: log error
			result.error('websocket not connected');
		}
		else {
			try {
				this._websocket.send(command);
				result.next();
			}
			catch (ex) {
				// TODO: log error
				result.error(ex);
			}
		}

		return result;
	}

	// #endregion
}
