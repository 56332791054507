import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { IViewModel } from 'channel/models/view.models';

import { IElementModel } from 'channel/models/element.models';
import { IElementUpdateModel } from 'element/models/element.models';
import { ELEMENT_LIST_ACTION, IElementListAction } from 'element/utility/element-list.action';

@Component({
	selector: 'stream-element-view',
	templateUrl: './element-view.component.html',
	styleUrls: ['./element-view.component.scss']
})
export class ElementViewComponent {
	@Input()
	public model: IViewModel;

	@HostBinding('class.view-collapse')
	@Input()
	public isCollapsed: boolean = false;

	@Output()
	public elementAction = new EventEmitter<IElementListAction>();

	public update(element: IElementModel): void {
		this.elementAction.emit({
			view: this.model.id,
			action: ELEMENT_LIST_ACTION.Update,
			element: {
				id: element.id,
				data: element.data || {}
			} as IElementUpdateModel
		} as IElementListAction);
	}

	public toggle(): void {
		this.isCollapsed = !this.isCollapsed;
	}
}
