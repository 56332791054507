import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IAuthResponseModel } from '../../models/auth.models';

import { IStorageService, StorageServiceToken } from '../storage/storage.service';

const enum STORAGE_KEYS {
	User = 'user'
}

@Injectable()
export class AuthManagerService {
	private _user: BehaviorSubject<IAuthResponseModel> = new BehaviorSubject<IAuthResponseModel>(null);

	constructor(@Inject(StorageServiceToken) private _storage: IStorageService) { }

	public setAuthentication(model: IAuthResponseModel): void {
		this._setValue(STORAGE_KEYS.User, model);

		this._user.next(model);
	}

	public clearAuthentication(): void {
		this._storage.reset();
	}

	public getUser(): BehaviorSubject<IAuthResponseModel> {
		return this._user;
	}

	public getToken(): string {
		const user = this._getValue(STORAGE_KEYS.User) as IAuthResponseModel;

		if (user) {
			return user.token;
		}

		return undefined;
	}

	// #region Internal

	private _getValue(key: STORAGE_KEYS): any {
		return this._storage.getValue(key);
	}

	private _setValue(key: STORAGE_KEYS, value?: any): void {
		if (value === undefined) {
			this._storage.clearValue(key);
		}
		else {
			this._storage.setValue(key, value);
		}
	}

	// #endregion
}
