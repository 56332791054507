import { Component, Input } from '@angular/core';
import { SOCIAL_CHANNEL_ENUM } from 'shared/utility/social-channels';

const iconBaseUrl = 'img';

@Component({
	selector: 'stream-social-icon',
	templateUrl: './social-icon.component.html',
	styleUrls: ['./social-icon.component.scss']
})
export class SocialIconComponent {
	@Input()
	public type: SOCIAL_CHANNEL_ENUM;

	public getSocialIcon(): string {
		return `${iconBaseUrl}/${this.type}_logo.png`;
	}
}
