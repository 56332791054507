<table class="table table-bordered">
	<thead>
		<tr>
			<th>ID</th>
			<th>Is Connected?</th>
			<th>Is Authorized?</th>
			<th>Last Activity</th>
			<th class="actions"></th>
		</tr>
	</thead>

	<tbody>
		<tr *ngFor="let frontend of frontends">
			<td>{{ frontend.id }}</td>
			<td class="flags">
				<i class="fas fa-check" *ngIf="isConnected(frontend)"></i>
			</td>
			<td class="flags">
				<i class="fas fa-check" *ngIf="frontend.connection.isAuthorized"></i>
			</td>
			<td>{{ formatDate(frontend) }}</td>
			<td class="actions">
				<div class="btn-group">
					<button type="button" class="btn btn-success btn-sm" (click)="authorize(frontend)" *ngIf="canAuthorize(frontend)" title="Authorize">
						<i class="fas fa-thumbs-up"></i>
					</button>
					<button type="button" class="btn btn-danger btn-sm" (click)="disconnect(frontend)" *ngIf="canDisconnect(frontend)" title="Disconnect">
						<i class="fas fa-times"></i>
					</button>
				</div>
			</td>
		</tr>
	</tbody>
</table>
