import { NgModule } from '@angular/core';

import { SocialIconComponent } from './components/social-icon/social-icon.component';

@NgModule({
	declarations: [
		SocialIconComponent
	],
	exports: [
		SocialIconComponent
	]
})
export class SharedModule { }
