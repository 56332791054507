<h1>Manage Donations</h1>

<div class="row">
	<div class="col-md-12 col-lg-6" *ngIf="hasSources">
		<div>
			<button type="button" class="btn btn-secondary" *ngFor="let sourceId of sourceIds" (click)="selectSource(sourceId)" [ngClass]="{'active': sourceId === selectedSourceId}">
				{{ sourceId}}
			</button>
		</div>

		<stream-donations-list [donations]="donations | async" (listAction)="doDonationAction($event)"></stream-donations-list>
	</div>

	<div class="col-md-12 col-lg-6" [ngClass]="{'col-lg-12': !hasSources, 'col-lg-6': hasSources}">
		<div class="panel" [class.panel-collapse]="isMilestonesCollapsed">
			<div class="panel-header" (click)="toggleMilestones()">Milestones</div>

			<div class="panel-actions">
				<button type="button" class="btn btn-success" (click)="addMilestone()">
					Add milestone
					<i class="fas fa-plus"></i>
				</button>

				<button type="button" class="btn btn-primary" (click)="sendMilestones()">
					Update milestones
					<i class="fas fa-share"></i>
				</button>
			</div>

			<div class="panel-content">
				<stream-milestones-admin [milestones]="milestones" [donationTotal]="donationTotal" (listAction)="doMilestoneAction($event)"></stream-milestones-admin>
			</div>
		</div>

		<div class="panel" [class.panel-collapse]="isBidsCollapsed">
			<div class="panel-header" (click)="toggleBids()">Bids</div>

			<div class="panel-actions">
				<button type="button" class="btn btn-success" (click)="addBidGroup()">
					Add bid group
					<i class="fas fa-plus"></i>
				</button>

				<button type="button" class="btn btn-primary" (click)="sendBids()">
					Update bids
					<i class="fas fa-share"></i>
				</button>
			</div>

			<div class="panel-content">
				<stream-bids-admin [bids]="bids" (listAction)="doBidAction($event)"></stream-bids-admin>
			</div>
		</div>

		<div class="panel" [class.panel-collapse]="isChallengesCollapsed">
			<div class="panel-header" (click)="toggleChallenges()">Challenges</div>

			<div class="panel-actions">
				<button type="button" class="btn btn-success" (click)="addChallenge()">
					Add challenge
					<i class="fas fa-plus"></i>
				</button>

				<button type="button" class="btn btn-primary" (click)="sendChallenges()">
					Update challenges
					<i class="fas fa-share"></i>
				</button>
			</div>

			<div class="panel-content">
				<stream-challenges-admin [challenges]="challenges" (listAction)="doChallengeAction($event)"></stream-challenges-admin>
			</div>
		</div>
	</div>
</div>
