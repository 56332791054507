import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IChallengeDataModel } from 'core/models/data.models';
import { formatCurrency } from 'core/utility/format.utility';

import { CHALLENGES_ACTION_TYPE, IChallengesAction } from 'donation/utility/challenges-action';

@Component({
	selector: 'stream-challenges-admin',
	templateUrl: './challenges-admin.component.html',
	styleUrls: ['./challenges-admin.component.scss']
})
export class ChallengesAdminComponent {
	@Input()
	public challenges: IChallengeDataModel[] = [];

	@Output()
	public listAction = new EventEmitter<IChallengesAction>();

	public remove(item: IChallengeDataModel): void {
		this.listAction.emit({
			type: CHALLENGES_ACTION_TYPE.Remove,
			item: item
		});
	}

	public decrement(item: IChallengeDataModel): void {
		item.count -= 1;

		if (item.count < 0) {
			item.count = 0;
		}
	}

	public increment(item: IChallengeDataModel): void {
		item.count += 1;
	}

	public formatTotal(item: IChallengeDataModel): string {
		return formatCurrency((item.count || 0) * (item.amount || 0));
	}
}
