import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IMilestoneDataModel } from 'core/models/data.models';
import { IMilestonesAction, MILESTONES_ACTION_TYPE } from 'donation/utility/milestones-action';

import { formatCurrency } from 'core/utility/format.utility';

@Component({
	selector: 'stream-milestones-admin',
	templateUrl: './milestones-admin.component.html',
	styleUrls: ['./milestones-admin.component.scss']
})
export class MilestonesAdminComponent {
	@Input()
	public milestones: IMilestoneDataModel[] = [];

	@Input()
	public donationTotal: number = 0;

	@Output()
	public listAction = new EventEmitter<IMilestonesAction>();

	public remove(item: IMilestoneDataModel): void {
		this.listAction.emit({
			type: MILESTONES_ACTION_TYPE.Remove,
			item: item
		});
	}

	public formatTotal(item: IMilestoneDataModel): string {
		return formatCurrency(this.donationTotal || 0);
	}
}
