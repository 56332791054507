import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { WEBSOCKET_STATE_TYPE } from 'core/models/websocket.models';
import { IFrontendInfoModel } from '../../models/frontend.models';
import { FRONTEND_LIST_ACTION, IFrontendListAction } from '../../utility/frontend-list.action';

import { getObjectValue } from 'core/utility/data.utility';

@Component({
	selector: 'stream-frontend-list',
	templateUrl: './frontend-list.component.html'
})
export class FrontendListComponent {
	@Input()
	public frontends: IFrontendInfoModel[] = [];

	@Input()
	public updateFrontend: Observable<IFrontendInfoModel>;

	@Output()
	public listAction = new EventEmitter<IFrontendListAction>();

	public authorize(frontend: IFrontendInfoModel): void {
		this.listAction.emit({
			action: FRONTEND_LIST_ACTION.Authorize,
			frontend: frontend
		} as IFrontendListAction);
	}

	public disconnect(frontend: IFrontendInfoModel): void {
		this.listAction.emit({
			action: FRONTEND_LIST_ACTION.Disconnect,
			frontend: frontend
		} as IFrontendListAction);
	}

	public isConnected(frontend: IFrontendInfoModel): boolean {
		return frontend.connection
			&& (frontend.connection.state === WEBSOCKET_STATE_TYPE.Open);
	}

	public canAuthorize(frontend: IFrontendInfoModel): boolean {
		return frontend.connection
			&& !frontend.connection.isAuthorized
			&& (frontend.connection.state === WEBSOCKET_STATE_TYPE.Open);
	}

	public canDisconnect(frontend: IFrontendInfoModel): boolean {
		return frontend.connection
			&& frontend.connection.state
			&& (frontend.connection.state === WEBSOCKET_STATE_TYPE.Open);
	}

	public formatDate(frontend: IFrontendInfoModel): string {
		const value = getObjectValue<Date>(frontend, 'connection.lastActivityDate', null);

		if (!value) {
			return 'Never';
		}

		return formatDate(value, 'dd MMM yyyy, HH:mm:ss', 'en-US', '-0500');
	}
}
