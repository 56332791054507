import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IScheduleDataModel } from 'core/models/data.models';
import { ApiBaseService } from 'core/services/api/api-base.service';

import { ISendScheduleRequestModel } from '../models/request.models';

@Injectable()
export class ScheduleService extends ApiBaseService {
	constructor(http: HttpClient) {
		super(http);
		this.baseUrl = ''; // TODO: should not send across multiple API bases - split to API/service
	}

	public sendSchedule(channel: string, profile: string, schedule: IScheduleDataModel[]): Observable<void> {
		const request = {
			channel: channel,
			profile: profile,
			schedule: schedule
		} as ISendScheduleRequestModel;

		return this.put<void>(`frontends/schedule`, request);
	}
}
