<div class="status-indicator">
	<img class="obs-logo" src="img/obs_logo.png" alt="OBS Studio" />

	<i class="fas fa-check connected" *ngIf="status === 'connected'" title="OBS connected"></i>
	<i class="fas fa-ban disconnected" *ngIf="status === 'disconnected'" title="OBS disconnected"></i>
</div>

<div class="status-controls">
	<button type="button" class="btn btn-default" *ngIf="status === 'connected'" (click)="disconnect()">Disconnect</button>
	<button type="button" class="btn btn-default" *ngIf="status === 'disconnected'" (click)="connect()">Connect</button>
</div>
