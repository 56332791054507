import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { RootStateService } from 'core/services/state/root-state.service';

import { AdminApiService } from './admin-api.service';

@Injectable()
export class AdminService {
	constructor(
		private _api: AdminApiService,
		private _state: RootStateService
	) { }

	public saveState(channel: string, profile: string): Observable<void> {
		return this._state.exportSaveableState()
			.pipe(
				switchMap((x) => {
					// TODO: is base64 encoding needed?
					const stateData = btoa(JSON.stringify(x));

					return this._api.saveState(channel, profile, stateData);
				})
			);
	}
}
