import { Component, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, combineLatest, filter, map, take, withLatestFrom } from 'rxjs/operators';

import { ProfileComponentBase } from 'core/components/base/profile-component.base';
import { AppStateService } from 'core/services/state/app-state.service';
import { IStorageService, StorageServiceToken } from 'core/services/storage/storage.service';

import { IViewModel } from 'channel/models/view.models';

import { ElementService } from 'element/services/element.service';

import { IElementUpdateModel } from 'element/models/element.models';
import { ELEMENT_LIST_ACTION, IElementListAction } from '../../utility/element-list.action';

@Component({
	selector: 'stream-element-admin',
	templateUrl: './element-admin.component.html',
	styleUrls: ['./element-admin.component.scss']
})
export class ElementAdminComponent extends ProfileComponentBase {
	public activeView: Observable<IViewModel>;
	public otherViews: Observable<IViewModel[]>;

	// public obsElements: ...[]

	constructor(
		appState: AppStateService,
		@Inject(StorageServiceToken) storage: IStorageService,
		private _elementService: ElementService
	) {
		super(appState, storage);
	}

	protected initObservables(): Observable<any>[] {
		return [
			...super.initObservables(),
			this.activeView = this.appState.watchActiveView(),
			this.otherViews = this.profileStream
				.pipe(
					combineLatest(this.activeView),
					map(([result, active]) => {
						if (!result || !active) {
							return [];
						}

						return result.views.filter((x) => x.id !== active.id);
					})
				)
		];
	}

	public doElementAction(action: IElementListAction): void {
		switch (action.action) {
			case ELEMENT_LIST_ACTION.Update:
				this.updateViewElement(action.view, action.element);
		}
	}

	// public doObsElementListAction ...

	// #region Internal

	private updateViewElement(view: string, element: IElementUpdateModel): void {
		this._elementService.updateElement(this.channel.id, this.profile.id, view, element)
			.pipe(
				take(1),
				catchError((err, _) => {
					console.warn(`Could not update element: ${err}`);
					return of(undefined);
				}),
				filter<void>((x) => x !== undefined)
			)
			.subscribe();
	}

	// private updateObsElement ...

	// #endregion
}
