import { IChannelModel } from 'channel/models/channel.models';
import { IFrontendInfoModel } from 'frontend/models/frontend.models';

export interface IAppState {
	channels: IChannelModel[];

	frontends: IFrontendInfoModel[];

	activeChannel: string;
	activeProfile: string;
	activeView: string;

	// TODO: state for (per-profile/view) element data
}

export const DEFAULT_APP_STATE: IAppState = {
	channels: [],

	frontends: [],

	activeChannel: null,
	activeProfile: null,
	activeView: null,
};
