import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiBaseService } from 'core/services/api/api-base.service';
import { IProfileTransitionRequestModel, IProfileVariantsRequestModel } from '../models/request.models';

@Injectable()
export class ProfileService extends ApiBaseService {
	constructor(http: HttpClient) {
		super(http);
		this.baseUrl = 'profiles';
	}

	public transition(channel: string, profile: string, frontends: string[], view: string, delay?: number, variants?: string[]): Observable<void> {
		return this.put('/transition', {
			channel: channel,
			profile: profile,
			frontends: frontends,
			view: view,
			delay: delay,
			variants: variants
		} as IProfileTransitionRequestModel);
	}

	public applyVariants(channel: string, profile: string, frontends: string[], variants: string[]): Observable<void> {
		return this.put('/variants', {
			channel: channel,
			profile: profile,
			frontends: frontends,
			variants: variants
		} as IProfileVariantsRequestModel);
	}
}
