import { NgRedux, Selector } from '@angular-redux/store';
import { AnyAction } from 'redux';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IRootState } from 'core/state/root-state.model';
import { deepCopy } from 'core/utility/data.utility';

export abstract class StateBaseService<Action extends AnyAction> {
	constructor(private _state: NgRedux<IRootState>) { }

	protected dispatch(action: Action): void {
		this._state.dispatch(action);
	}

	protected select = <T>(chainSelector: Selector<IRootState, T>): Observable<T> => this._state
		.select(chainSelector)
		.pipe(
			map((x) => deepCopy(x))
		)
}
