<table class="table table-bordered">
	<thead>
		<tr>
			<th style="min-width: 20rem">Game Name</th>
			<th style="min-width: 8.5rem">Platform</th>
			<th style="min-width: 7rem">Year</th>
			<th style="min-width: 15rem">Runner(s)</th>
			<th style="min-width: 10rem">Category</th>
			<th style="min-width: 6.8rem">Estimate</th>
			<th class="actions"></th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let item of schedule; index as i" [title]="i === 0 ? 'Current game' : ''" [class.deleted]="item._delete">
			<td>
				<input type="text" name="gameName" class="form-control" [(ngModel)]="item.gameName" (ngModelChange)="modify()" />
			</td>
			<td>
				<input type="text" name="gamePlatform" class="form-control" [(ngModel)]="item.gamePlatform" (ngModelChange)="modify()" />
			</td>
			<td>
				<input type="number" name="gameReleaseYear" class="form-control" min="1969" max="2099" step="1" [(ngModel)]="item.gameReleaseYear" (ngModelChange)="modify()" />
			</td>
			<td>
				<input type="text" name="runners" class="form-control" [(ngModel)]="item.runners" (ngModelChange)="modify()" />
			</td>
			<td>
				<input type="text" name="runCategory" class="form-control" [(ngModel)]="item.runCategory" (ngModelChange)="modify()" />
			</td>
			<td>
				<input type="text" name="expectedDuration" class="form-control" [(ngModel)]="item.expectedDuration" (ngModelChange)="modify()" />
			</td>
			<td class="actions">
				<div class="btn-group">
					<button type="button" class="btn btn-default btn-sm" (click)="moveUp(i)" title="Move up">
						<i class="fas fa-arrow-up"></i>
					</button>
					<button type="button" class="btn btn-default btn-sm" (click)="moveDown(i)" title="Move down">
						<i class="fas fa-arrow-down"></i>
					</button>
					<button type="button" class="btn btn-danger btn-sm" (click)="remove(i)" title="Remove" *ngIf="!item._delete">
						<i class="fas fa-times"></i>
					</button>
					<button type="button" class="btn btn-default btn-sm undelete" (click)="remove(i)" title="Undo remove" *ngIf="item._delete === true">
						<i class="fas fa-undo"></i>
					</button>
				</div>
			</td>
		</tr>
	</tbody>
</table>
