import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ObsStatusComponent } from './components/obs-status/obs-status.component';
import { obsServiceToken } from './services/obs.service';
import { WebsocketObsService } from './services/websocketObs.service';

@NgModule({
	declarations: [
		ObsStatusComponent
	],
	imports: [
		CommonModule,
		FormsModule
	],
	providers: [
		{ provide: obsServiceToken, useClass: WebsocketObsService }
	],
	exports: [
		ObsStatusComponent
	]
})
export class ObsModule { }
