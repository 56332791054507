import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthManagerService } from './auth-manager.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
	constructor(
		private _router: Router,
		private _authManager: AuthManagerService,
		private _authService: AuthService
	) { }

	public canActivate(route: ActivatedRouteSnapshot, routeState: RouterStateSnapshot): Observable<boolean> {
		const token = this._authManager.getToken();

		if (token) {
			return this._authService.check();
		}

		this._router.navigate(['/login']);

		return of(false);
	}
}
