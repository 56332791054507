<h1>Manage Social Media</h1>

<div class="row">
	<div class="col-md-12 col-lg-6">
		<h2>Generate Tweet Text</h2>

		<div class="form-group row">
			<label class="col-sm-4 col-md-3 col-form-label">Template</label>
			<div class="col-sm-8 col-md-9">
				<select class="form-control" name="tweetTemplate" ngModel (ngModelChange)="generateTweet($event)">
					<option *ngFor="let template of tweetTemplates" [value]="template.id">{{ template.name }}</option>
				</select>
			</div>
		</div>

		<div class="form-group row">
			<label class="col-sm-4 col-md-3 col-form-label">Tweet content</label>
			<div class="col-sm-8 col-md-9">
				<textarea class="form-control" [value]="tweetText" (click)="selectTweet($event)" style="height: 12rem;"></textarea>
			</div>
		</div>
	</div>
</div>
