<div class="container-fluid">
	<form name="login" (submit)="login()">
		<div class="row">
			<label class="col-md-4 col-form-label">
				Channel
			</label>
			<div class="col-md-8">
				<input type="text" name="channel" class="form-control" [(ngModel)]="model.channel" />
			</div>
		</div>

		<div class="row">
			<label class="col-md-4 col-form-label">
				User name
			</label>
			<div class="col-md-8">
				<input type="text" name="username" class="form-control" [(ngModel)]="model.username" />
			</div>
		</div>

		<div class="row">
			<label class="col-md-4 col-form-label">
				Password
			</label>
			<div class="col-md-8">
				<input type="password" name="password" class="form-control" [(ngModel)]="model.password" />
			</div>
		</div>

		<div class="row">
			<div class="col-md-12 actions">
				<button type="submit" class="btn btn-success">Log in</button>
			</div>
		</div>
	</form>
</div>
