<div class="element-meta">
	<div class="element-meta-type">
		<i [class]="getTypeIcon()"></i>
	</div>
	<div class="element-meta-name">
		{{ model.name }}
	</div>
	<div class="element-meta-visibility">
		<div class="btn-group">
			<button type="button" class="btn" [ngClass]="{'btn-default': model.data.isVisible === false, 'btn-active btn-primary': model.data.isVisible === true}" (click)="setVisibility(true)" title="Show element">
				<i class="fas fa-eye"></i>
			</button>
			<button type="button" class="btn" [ngClass]="{'btn-default': model.data.isVisible === true, 'btn-active btn-danger': model.data.isVisible === false}" (click)="setVisibility(false)" title="Hide element">
				<i class="fas fa-eye-slash"></i>
			</button>
		</div>
	</div>
	<div class="element-meta-actions">
		<button type="button" class="btn btn-primary" (click)="commitUpdate()" title="Update element">
			<i class="fas fa-share"></i>
		</button>
	</div>
</div>

<div class="element-controls" [class.empty]="!hasControls">
	<ng-container #controls></ng-container>
</div>
