import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { ObservableComponentBase } from '../base/observable-component.base';

@Component({
	selector: 'stream-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends ObservableComponentBase implements OnInit {
	constructor(
		private _authService: AuthService,
	) {
		super();
	}

	public ngOnInit(): void {
		super.ngOnInit();
	}

	public logout(): void {
		this._authService.logout();
	}
}
