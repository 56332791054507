import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';

import { StateBaseService } from './state-base.service';

import { IRootStateAction, ROOT_STATE_ACTIONS } from 'core/state/root-state.actions';
import { getRootState, IRootState } from 'core/state/root-state.model';
import { createSelector } from 'reselect';
import { Observable } from 'rxjs';

const getSaveableState = createSelector(getRootState, (x: IRootState) => {
	return {
		app: {
			activeChannel: x.app.activeChannel,
			activeProfile: x.app.activeProfile,
			activeView: x.app.activeView
			// activeVariants: x.app.activeView.variants // TODO: only save active variants https://trello.com/c/eVGM6Lq4
		},
		data: x.data
	};
});

@Injectable()
export class RootStateService extends StateBaseService<IRootStateAction> {
	constructor(state: NgRedux<IRootState>) {
		super(state);
	}

	public importSavedState = (data: object): void => this.dispatch({
		type: ROOT_STATE_ACTIONS.IMPORT_SAVED_STATE,
		payload: data
	} as IRootStateAction)

	public exportSaveableState = (): Observable<object> => {
		// specific app state keys, full data state, no ui state
		return this.select(getSaveableState);
	}
}
