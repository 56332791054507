import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiBaseService } from 'core/services/api/api-base.service';

import { IElementUpdateRequestModel } from 'element/models/element-api.models';
import { IElementUpdateModel } from 'element/models/element.models';

@Injectable()
export class ElementService extends ApiBaseService {
	constructor(http: HttpClient) {
		super(http);
		this.baseUrl = 'elements';
	}

	public updateElement(channel: string, profile: string, view: string, element: IElementUpdateModel): Observable<void> {
		return this.put<void>('', {
			channel: channel,
			profile: profile,
			view: view,
			element: element
		} as IElementUpdateRequestModel);
	}
}
