import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IDonationDataModel } from 'core/models/data.models';

import { IDonationsUpdate } from '../../models/donations.models';
import { DONATIONS_LIST_ACTION_TYPE, IDonationsListAction } from '../../utility/donations-list.action';

import { formatCurrency } from 'core/utility/format.utility';

@Component({
	selector: 'stream-donations-list',
	templateUrl: './donations-list.component.html',
	styleUrls: ['./donations-list.component.scss']
})
export class DonationsListComponent {
	@Input()
	public donations: IDonationsUpdate = {
		donations: [],
		sourceId: null,
		lastUpdate: null
	} as IDonationsUpdate;

	@Output()
	public listAction = new EventEmitter<IDonationsListAction>();

	public markDone(item: IDonationDataModel): void {
		item.isDone = true;

		this.listAction.emit({
			type: DONATIONS_LIST_ACTION_TYPE.MarkDone,
			sourceId: this.donations.sourceId,
			item: item
		} as IDonationsListAction);
	}

	// TODO: more generic formatting
	public formatDate(value: Date): string {
		return formatDate(value, 'dd MMM yyyy, HH:mm:ss', 'en-US', '-0500');
	}

	public formatAmount(value: number): string {
		return formatCurrency(value);
	}
}
