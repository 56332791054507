import { combineReducers, Reducer } from 'redux';

import { appReducer } from './app-state.reducer';
import { dataReducer } from './data-state.reducer';
import { uiReducer } from './ui-state.reducer';

import { ROOT_STATE_ACTIONS } from './root-state.actions';
import { DEFAULT_ROOT_STATE, IRootState } from './root-state.model';
import { IStateAction } from './state-actions';

const combinedSubreducers = combineReducers<IRootState>({
	app: appReducer,
	data: dataReducer,
	ui: uiReducer
});

// attach and process root actions first, then cascade to subreducers
export const rootReducer: Reducer<IRootState, IStateAction> = (state: IRootState = DEFAULT_ROOT_STATE, action: IStateAction): IRootState => {
	switch (action.type) {
		case ROOT_STATE_ACTIONS.IMPORT_SAVED_STATE:
			return {
				// start with existing state values
				...state,
				// ...then merge saved data state
				data: { ...action.payload.data }
				// do not load app state - handled elsewhere
				// do not save _or_ load UI state
			};
	}

	return combinedSubreducers(state, action);
};
