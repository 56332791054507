import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TwitchChatAsComponent } from './components/chat/chat.component';
import { TwitchIrcService } from './services/twitchIrc.service';

@NgModule({
	declarations: [
		TwitchChatAsComponent
	],
	imports: [
		CommonModule,
		FormsModule
	],
	providers: [
		TwitchIrcService
	],
	exports: [
		TwitchChatAsComponent
	]
})
export class TwitchModule { }
