import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ObservableComponentBase } from 'core/components/base/observable-component.base';

import { AdminService } from 'admin/services/admin.service';
import { AppStateService } from 'core/services/state/app-state.service';
import { DataStateService } from 'core/services/state/data-state.service';
import { UIStateService } from 'core/services/state/ui-state.service';
import { IStorageService, StorageServiceToken } from 'core/services/storage/storage.service';
import { IWebsocketService, websocketServiceToken } from 'core/services/websockets/websocket.service';

import { IDonationDataModel, IDonationTotalDataModel, IScheduleDataModel } from 'core/models/data.models';
import { IWebsocketMessageModel, WEBSOCKET_MESSAGE_TYPE } from 'core/models/websocket.models';

import { IProfileModel } from 'channel/models/profile.models';
import { ChannelService } from 'channel/services/channel.service';

import { ProfileManager } from 'profile/services/profile.manager';

import { getObjectValue } from 'core/utility/data.utility';
import { IElementDataModel } from 'element/models/element.models';
import { TwitchIrcService } from 'integrations/twitch/services/twitchIrc.service';

@Component({
	selector: 'stream-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})
export class MainComponent extends ObservableComponentBase implements OnInit, OnDestroy {
	public isChatEnabled: boolean = false;
	public showChat: boolean = false;

	private _channel: string;
	private _profile: string;
	private _stateTimer: number;

	constructor(
		@Inject(websocketServiceToken) private _websocketService: IWebsocketService,
		private _appState: AppStateService,
		private _dataState: DataStateService,
		private _uiState: UIStateService,
		@Inject(StorageServiceToken) private _storage: IStorageService,
		private _adminService: AdminService,
		private _channelService: ChannelService,
		private _profileManager: ProfileManager,
		private _twitchIrc: TwitchIrcService
	) {
		super();
	}

	protected initSubscriptions(): Subscription[] {
		return [
			this._appState.watchProfiles()
				.subscribe((result) => {
					if (!result || result.length < 1) { return; }

					let loadProfile: IProfileModel = null;

					const previousProfile = this._storage.getValue('activeProfile');
					if (previousProfile) {
						loadProfile = result.find((x) => x.id === previousProfile);
					}

					if (!loadProfile) {
						loadProfile = result[0];
					}

					this._profileManager.load(loadProfile);
				}),
			this._appState.watchActiveProfile()
				.subscribe((result) => {
					if (!result) { return; }

					// stop saving previous profile's state (if present), start saving new profile's state
					this.stopSavingState();	// TODO: triggering state save on profile switch incurs race condition with loading incoming state
					this._channel = result.channel;
					this._profile = result.id;
					this.startSavingState();
				}),
			this._uiState.watchIsChatExpanded()
				.subscribe((isChatExpanded) => {
					this.showChat = isChatExpanded;
				}),
			this._twitchIrc.configStream
				.subscribe((result) => {
					this.isChatEnabled = (result !== null);
				})
		];
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this._websocketService.connect();

		// TODO: attach / detach handlers when changing profiles, according to what data profile supports?
		// TODO: clear / reload saved state when changing profiles

		// handle incoming donation totals
		this._websocketService.handle(WEBSOCKET_MESSAGE_TYPE.DonationTotals, (message: IWebsocketMessageModel): void => {
			const data = message.data as IDonationTotalDataModel;

			// TODO: log incoming data

			this._dataState.storeDonationTotal(data, message.sourceId);
		});

		// handle incoming donation data
		this._websocketService.handle(WEBSOCKET_MESSAGE_TYPE.Donations, (message: IWebsocketMessageModel): void => {
			const data = message.data as IDonationDataModel[];

			// TODO: log incoming data

			this._dataState.storeDonations(data, message.sourceId);
		});

		// handle incoming schedule data
		this._websocketService.handle(WEBSOCKET_MESSAGE_TYPE.Schedule, (message: IWebsocketMessageModel): void => {
			const data = message.data as IScheduleDataModel[];

			// TODO: log incoming data

			this._dataState.storeIncomingSchedule(data, message.sourceId);
		});

		// TODO: handle incoming music data

		// load active channel
		const activeChannel = this._storage.getValue('activeChannel');
		if (activeChannel) {
			this._channelService.getChannel(activeChannel)
				.pipe(
					take(1)
				)
				.subscribe((result) => {
					// apply basic defaults to all elements within all views within all profiles
					for (const profile of result.profiles) {
						for (const view of profile.views) {
							for (const element of view.elements) {
								element.data = {
									...element.data,
									isVisible: getObjectValue(element.data, 'isVisible', true)
								} as IElementDataModel;
							}
						}
					}

					this._appState.storeChannels([result]);
					this._appState.storeActiveChannel(result.id);
				});
		}
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();

		this._websocketService.disconnect();
		this.stopSavingState();
	}

	// #region Internal

	private startSavingState(): void {
		if (!this._stateTimer) {
			this._stateTimer = setInterval(() => { this.saveState(); }, 60000) as any as number;
		}
	}

	private stopSavingState(): void {
		if (this._stateTimer) {
			clearInterval(this._stateTimer);
			this._stateTimer = 0;
		}
	}

	private saveState(): void {
		this._adminService.saveState(this._channel, this._profile)
			.pipe(
				take(1)
			)
			.subscribe(); // TODO: log error
	}

	// #endregion
}
