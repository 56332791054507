<div *ngIf="!milestones || !milestones.length">
	No milestones available
</div>

<table class="table table-bordered" [class.empty]="!milestones || !milestones.length">
	<thead>
		<tr>
			<th>Description</th>
			<th>Amount</th>
			<th>Goal</th>
			<th>Active?</th>
			<th class="actions"></th>
		</tr>
	</thead>

	<tbody>
		<tr *ngFor="let item of milestones" [class.deleted]="item._delete">
			<td>
				<input type="text" [name]="'description' + item.id" class="form-control" [(ngModel)]="item.description" />
			</td>
			<td>
				<div *ngIf="item.useTotal">{{ formatTotal(item) }}</div>

				<div *ngIf="!item.useTotal">
					<input type="number" [name]="'amount' + item.id" min="0" step="0.01" class="form-control" [(ngModel)]="item.amount" />
				</div>

				<div class="form-check form-check-inline">
					<input type="checkbox" [name]="'useTotal' + item.id" class="form-check-input" [(ngModel)]="item.useTotal" />
					<label class="form-check-label">Use donation total</label>
				</div>
			</td>
			<td>
				<input type="number" [name]="'goal' + item.id" min="0" step="0.01" class="form-control" [(ngModel)]="item.goal" />
			</td>
			<td class="flags">
				<input type="checkbox" [name]="'isActive' + item.id" [(ngModel)]="item.isActive" />
			</td>
			<td class="actions">
				<button class="btn btn-danger" title="Remove" (click)="remove(item)" *ngIf="!item._delete">
					<i class="fas fa-times"></i>
				</button>
				<button class="btn btn-default undelete" title="Undo remove" (click)="remove(item)" *ngIf="item._delete === true">
					<i class="fas fa-undo"></i>
				</button>
			</td>
		</tr>
	</tbody>
</table>
