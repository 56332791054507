<div *ngIf="!bids || !bids.length">
	No bids available
</div>

<table class="table table-bordered" *ngFor="let group of bids" [class.empty]="!bids || !bids.length" [class.deleted]="group._delete">
	<thead>
		<tr>
			<th>
				<input type="text" [name]="'groupDesc' + group.id" class="form-control" [(ngModel)]="group.description" />
			</th>
			<th class="flags">
				<div class="form-check form-check-inline">
					<input type="checkbox" [name]="'groupActive' + group.id" class="form-check-input" [(ngModel)]="group.isActive" />
					<label class="form-check-label">Active?</label>
				</div>
			</th>
			<th class="actions">
				<button class="btn btn-danger" title="Remove bid group" (click)="removeGroup(group)" *ngIf="!group._delete">
					<i class="fas fa-times"></i>
				</button>
				<button class="btn btn-default undelete" title="Undo remove" (click)="removeGroup(group)" *ngIf="group._delete === true">
					<i class="fas fa-undo"></i>
				</button>
			</th>
		</tr>

		<tr>
			<th>Option</th>
			<th>Amount</th>
			<th class="actions">
				<button class="btn btn-success" title="Add bid option" (click)="addOption(group)">
					<i class="fas fa-plus"></i>
				</button>
			</th>
		</tr>
	</thead>

	<tbody>
		<tr *ngFor="let option of group.options" [class.deleted]="option._delete">
			<td>
				<input type="text" [name]="'optionDesc' + option.id" class="form-control" [(ngModel)]="option.description" />
			</td>
			<td>
				<input type="number" [name]="'optionAmount' + option.id" min="0" step="0.01" class="form-control" [(ngModel)]="option.amount" />
			</td>
			<td class="actions">
				<button class="btn btn-danger" title="Remove bid option" (click)="removeOption(group, option.id)" *ngIf="!option._delete">
					<i class="fas fa-times"></i>
				</button>
				<button class="btn btn-default undelete" title="Undo remove" (click)="removeOption(group, option.id)" *ngIf="option._delete === true">
					<i class="fas fa-undo"></i>
				</button>
			</td>
		</tr>
	</tbody>
</table>
