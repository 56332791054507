import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'environment';
import { Observable } from 'rxjs';

export abstract class ApiBaseService {
	protected baseUrl: string;

	constructor(private _http: HttpClient) { }

	protected get<T>(path: string, data: any = null): Observable<T> {
		const url = this.buildUrl(path);

		const options: {
			headers?: HttpHeaders,
			observe?: 'body',
			params?: HttpParams,
			reportProgress?: boolean,
			responseType?: 'json',
			withCredentials?: boolean
		} = this.buildRequestParams(data);

		return this._http.get<T>(url, options);
	}

	protected post<T>(path: string, data: any): Observable<T> {
		const url = this.buildUrl(path);

		return this._http.post<T>(url, data);
	}

	protected put<T>(path: string, data: any): Observable<T> {
		const url = this.buildUrl(path);

		return this._http.put<T>(url, data);
	}

	protected delete<T>(path: string): Observable<T> {
		const url = this.buildUrl(path);

		return this._http.delete<T>(url);
	}

	// #region Internal

	private buildRequestParams(data: any): any {
		let params = new HttpParams();

		if (!data) { return params; }

		for (const key of Object.keys(data)) {
			// skip parameter if value is undefined or null
			if ((data[key] === undefined) || (data[key] === null)) {
				continue;
			}

			params = params.set(key, data[key]);
		}

		return { params: params, observe: 'body' };
	}

	private buildUrl(path: string): string {
		return `${environment.backendServerUrl}/${this.baseUrl}${path}`;
	}

	// #endregion
}
