<div class=channel>
	{{ channel?.name }}
</div>

<div class="profile">
	<div class="input-group">
		<div class="input-group-prepend">
			<span class="input-group-text">
				Profile
			</span>
		</div>
		<select class="form-control" name="profiles" [ngModel]="selectedProfile?.id" [disabled]="profiles.length < 2" (ngModelChange)="selectProfile($event)">
			<option *ngFor="let profile of profiles" [value]="profile.id">{{ profile.id }}</option>
		</select>
	</div>
</div>

<div class="frontend-transition">
	<div class="frontend-transition-selector input-group">
		<div class="input-group-prepend">
			<span class="input-group-text">
				Transition from&nbsp;<span class="bold" [innerText]="activeView?.name"></span>&nbsp;to
			</span>
		</div>
		<select class="form-control" name="transition" [ngModel]="selectedView?.id" (ngModelChange)="selectView($event)" [disabled]="isAwaitingTransition">
			<option [value]="null">-</option>
			<option *ngFor="let view of profile?.views" [value]="view.id">{{ view.name }}</option>
		</select>
		<div class="input-group-append frontend-transition-selector-variants" [class.empty]="!variants.length">
			<span class="input-group-text">{{ selectedVariants.length }} of {{ variants.length }} variants</span>

			<ul class="frontend-transition-selector-variants-list">
				<li class="variant-entry" *ngFor="let variant of variants" (click)="toggleVariant(variant)" [class.active]="isVariantSelected(variant)">
					{{ variant }}
				</li>
			</ul>
		</div>
		<div class="input-group-append">
			<button type="button" class="btn btn-primary" *ngIf="!isAwaitingTransition && (!variants.length || selectedView)" (click)="startTransition()" [disabled]="!selectedView">Start transition</button>
			<button type="button" class="btn btn-primary" *ngIf="isAwaitingTransition" (click)="completeTransition()">Complete transition</button>
			<button type="button" class="btn btn-primary" *ngIf="variants.length && !selectedView" (click)="applyVariants()" [disabled]="!isAwaitingVariants">Apply</button>
		</div>
	</div>
</div>

<div class="chat-toggle" *ngIf="isChatEnabled">
	<button class="btn chat-toggle" (click)="toggleChat()" [title]="isChatExpanded ? 'Hide chat' : 'Show chat'" [ngClass]="{'btn-primary active': isChatExpanded, 'btn-default': !isChatExpanded}">
		<i class="fas fa-comments"></i>
	</button>
</div>

