<div class="section">
	<ul>
		<li routerLinkActive="active">
			<a [routerLink]="['/Command']">
				<i class="fas fa-play"></i> Commands
			</a>
		</li>
		<li routerLinkActive="active">
			<a [routerLink]="['/Frontend']">
				<i class="fas fa-plug"></i> Frontends
			</a>
		</li>
		<li routerLinkActive="active">
			<a [routerLink]="['/Element']">
				<i class="fas fa-image"></i> Elements
			</a>
		</li>
		<li routerLinkActive="active">
			<a [routerLink]="['/Donation']">
				<i class="fas fa-dollar-sign"></i> Donations
			</a>
		</li>
		<li routerLinkActive="active">
			<a [routerLink]="['/People']">
				<i class="fas fa-user"></i> People
			</a>
		</li>
		<li routerLinkActive="active">
			<a [routerLink]="['/Schedule']">
				<i class="fas fa-calendar"></i> Schedule
			</a>
		</li>
		<li routerLinkActive="active">
			<a [routerLink]="['/Social']">
				<i class="fas fa-bullhorn"></i> Social Media
			</a>
		</li>
	</ul>
</div>

<hr />

<div class="section">
	<ul>
		<li routerLinkActive="active">
			<a [routerLink]="['/Data']"><i class="fas fa-database"></i> Data Management</a>
		</li>
	</ul>
</div>

<hr />

<div class="actions">
	<button type="button" class="btn btn-secondary" (click)="logout() "><i class="fas fa-sign-out-alt"></i></button>
</div>
