import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IScheduleDataModel } from 'core/models/data.models';

@Component({
	selector: 'stream-schedule-outgoing-list',
	templateUrl: './schedule-outgoing-list.component.html',
	styleUrls: ['./schedule-outgoing-list.component.scss']
})
export class ScheduleOutgoingListComponent {
	@Input()
	public schedule: IScheduleDataModel[] = [];

	@Output()
	public modifyAction = new EventEmitter<void>();

	public moveUp(index: number): void {
		if (index === 0) { return; }

		const toMove = this.schedule.splice(index, 1);
		this.schedule.splice(index - 1, 0, toMove[0]);

		this.modify();
	}

	public moveDown(index: number): void {
		if (index >= (this.schedule.length - 1)) { return; }

		const toMove = this.schedule.splice(index, 1);
		this.schedule.splice(index + 1, 0, toMove[0]);

		this.modify();
	}

	public remove(index: number): void {
		this.schedule[index]._delete = this.schedule[index]._delete === true ? false : true;

		this.modify();
	}

	public modify(): void {
		this.modifyAction.emit();
	}
}
