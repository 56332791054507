<table class="table table-bordered" *ngIf="donations">
	<caption>
		Last update: {{ donations.lastUpdate }}
	</caption>

	<thead>
		<tr>
			<th>Date</th>
			<th>Amount</th>
			<th>Name</th>
			<th>Comment</th>
			<th class="actions"></th>
		</tr>
	</thead>

	<tbody>
		<tr class="donation" *ngFor="let item of donations.donations" [class.done]="item.isDone" [title]="item.isDone ? 'Donation acknowledged' : ''">
			<td>{{ formatDate(item.date) }}</td>
			<td>{{ formatAmount(item.amount) }}</td>
			<td>
				<input type="text" [name]="'name' + item.id" class="form-control" [(ngModel)]="item.name" [disabled]="item.isDone" />
			</td>
			<td>
				<textarea [name]="'comment' + item.id" class="form-control" [(ngModel)]="item.comment" [disabled]="item.isDone"></textarea>
			</td>
			<td class="actions">
				<button *ngIf="!item.isDone" class="btn btn-success btn-sm" title="Mark done" (click)="markDone(item)">
					<i class="fas fa-check"></i>
				</button>
			</td>
		</tr>
	</tbody>
</table>
