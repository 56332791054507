import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPlayerDataModel } from 'core/models/data.models';
import { ApiBaseService } from 'core/services/api/api-base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPersonInfoModel } from '../models/person.models';
import { IPersonListResponseModel, ISendPlayersRequestModel } from '../models/request.models';

@Injectable()
export class PersonService extends ApiBaseService {
	constructor(http: HttpClient) {
		super(http);
		this.baseUrl = '';	// TODO: should not send across multiple API bases - split to API/service
	}

	public getList(channel: string, profile?: string): Observable<IPersonInfoModel[]> {
		return this.get<IPersonListResponseModel>(`channels/${channel}/people`, { profile: profile })
			.pipe(
				map((result) => {
					return result.people;
				})
			);
	}

	public sendPlayers(channel: string, profile: string, people: IPersonInfoModel[]): Observable<void> {
		const players = people.map((x, index) => {
			return {
				alias: x.alias,
				hasGameAudio: x.hasGameAudio,
				pronouns: x.pronouns,
				channels: x.channels,
				index: index
			} as IPlayerDataModel;
		});

		const request = {
			channel: channel,
			profile: profile,
			players: players
		} as ISendPlayersRequestModel;

		return this.put<void>(`frontends/players`, request);
	}

	// public sendAttendees(attendees: IPersonInfoModel[]): void {}

	// public sendCommentators(commentators: IPersonInfoModel[]): void {}
}
