import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IPersonInfoModel } from '../../models/person.models';
import { IPeopleListAction, PEOPLE_LIST_ACTION_TYPE } from '../../utility/people-list.action';

@Component({
	selector: 'stream-people-list',
	templateUrl: './people-list.component.html',
	styleUrls: ['./people-list.component.scss']
})
export class PeopleListComponent {
	public mode = 'players';

	@Input()
	public people: IPersonInfoModel[] = [];

	@Output()
	public listAction = new EventEmitter<IPeopleListAction>();

	public setPlayer(person: IPersonInfoModel, index: number) {
		/* First player normally has the game audio, so mark that index as has
		 * audio by default.*/
		if (index === 0) { person.hasGameAudio = true; }
		/* If person was index 0 previously, it would mark them incorrectly as having game audio.
		 * Setting back to false when adding player to make behavior consistent.*/
		else { person.hasGameAudio = false; }

		this.listAction.emit({
			type: PEOPLE_LIST_ACTION_TYPE.SetPlayer,
			person: person,
			index: index
		} as IPeopleListAction);
	}
}
