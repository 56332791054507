import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthManagerService } from '../auth/auth-manager.service';

@Injectable()
export class HandleJwtInterceptor implements HttpInterceptor {
	constructor(
		private _authManager: AuthManagerService,
		private _router: Router
	) { }

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = this._authManager.getToken();

		req = req.clone({
			setHeaders: {
				Authorization: 'Bearer ' + token
			}
		});

		return next.handle(req)
			.pipe(
				catchError((err, obs) => {
					if (err.status === 401) {
						this._authManager.clearAuthentication();
						this._router.navigateByUrl('/login');
					}

					return throwError(err);
				}));
	}
}
