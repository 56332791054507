import { createSelector } from 'reselect';

import { DEFAULT_APP_STATE, IAppState } from './app-state.model';
import { DEFAULT_DATA_STATE, IDataState } from './data-state.model';
import { DEFAULT_UI_STATE, IUIState } from './ui-state.models';

export interface IRootState {
	app: IAppState;
	data: IDataState;
	ui: IUIState;
}

export const DEFAULT_ROOT_STATE = {
	app: DEFAULT_APP_STATE,
	data: DEFAULT_DATA_STATE,
	ui: DEFAULT_UI_STATE
};

export const getRootState = (state: IRootState) => state;

// substate trees
export const getAppState = createSelector(getRootState, (state: IRootState) => state.app);
export const getDataState = createSelector(getRootState, (state: IRootState) => state.data);
export const getUIState = createSelector(getRootState, (state: IRootState) => state.ui);
