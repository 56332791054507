import { Routes } from '@angular/router';

import { LoginComponent } from 'core/components/login/login.component';
import { MainComponent } from 'core/components/main/main.component';
import { SidebarComponent } from 'core/components/sidebar/sidebar.component';

import { COMMAND_ROUTES } from 'command/command.routes';
import { DATA_MANAGEMENT_ROUTES } from 'data-management/data-management.routes';
import { DONATION_ROUTES } from 'donation/donation.routes';
import { ELEMENT_ROUTES } from 'element/element.routes';
import { FRONTEND_ROUTES } from 'frontend/frontend.routes';
import { PEOPLE_ROUTES } from 'people/people.routes';
import { SCHEDULE_ROUTES } from 'schedule/schedule.routes';
import { SOCIAL_ROUTES } from 'social/social.routes';

import { AuthGuardService } from 'core/services/auth/auth-guard.service';

export const APP_ROUTES: Routes = [{
	path: 'login', component: LoginComponent
}, {
	path: '', component: MainComponent, canActivate: [AuthGuardService], children: [{
		path: '', component: SidebarComponent, outlet: 'sidebar', pathMatch: 'prefix'
	}, {
		path: '', children: [
			...COMMAND_ROUTES,
			...DATA_MANAGEMENT_ROUTES,
			// ...DASHBOARD_ROUTES,
			...DONATION_ROUTES,
			...ELEMENT_ROUTES,
			...FRONTEND_ROUTES,
			...PEOPLE_ROUTES,
			...SCHEDULE_ROUTES,
			...SOCIAL_ROUTES,
			{
				path: '**', redirectTo: '/login', pathMatch: 'full'
			}
		]
	}]
}];
