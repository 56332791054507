import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DataManagementAdminComponent } from './components/data-management-admin/data-management-admin.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule
	],
	declarations: [
		DataManagementAdminComponent
	],
	providers: [],
	exports: []
})
export class DataManagementModule { }
