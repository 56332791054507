import { NgModule } from '@angular/core';
import { AdminApiService } from './services/admin-api.service';
import { AdminService } from './services/admin.service';

@NgModule({
	providers: [
		AdminApiService,
		AdminService
	]
})
export class AdminModule { }
