import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
import { environment } from 'environment';
import { StoreEnhancer } from 'redux';

// main layout components
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

// authentication / authorization
import { AuthGuardService } from './services/auth/auth-guard.service';
import { AuthManagerService } from './services/auth/auth-manager.service';
import { AuthService } from './services/auth/auth.service';

// HTTP
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HandleJwtInterceptor } from './services/http/handleJwt.http';

// websockets
import { NativeWebsocketService } from './services/websockets/nativeWebsocket.service';
import { websocketServiceToken } from './services/websockets/websocket.service';

// storage
import { LocalStorageModule } from 'angular-2-local-storage';
import { LocalStorageService } from './services/storage/local-storage.service';
import { StorageServiceToken } from './services/storage/storage.service';

// integrations
import { ObsModule } from 'integrations/obs/obs.module';
import { TwitchModule } from 'integrations/twitch/twitch.module';

// state
import { AppStateService } from './services/state/app-state.service';
import { DataStateService } from './services/state/data-state.service';
import { RootStateService } from './services/state/root-state.service';
import { UIStateService } from './services/state/ui-state.service';
import { DEFAULT_ROOT_STATE, IRootState } from './state/root-state.model';
import { rootReducer } from './state/root-state.reducer';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		HttpClientModule,
		NgReduxModule,

		LocalStorageModule.forRoot({
			prefix: 'stream',
			storageType: 'localStorage'
		}),

		ObsModule,
		TwitchModule
	],
	declarations: [
		FooterComponent,
		HeaderComponent,
		LoginComponent,
		MainComponent,
		SidebarComponent
	],
	providers: [
		AuthService,
		AuthGuardService,
		AuthManagerService,

		RootStateService,
		AppStateService,
		DataStateService,
		UIStateService,

		{ provide: StorageServiceToken, useClass: LocalStorageService },
		{ provide: websocketServiceToken, useClass: NativeWebsocketService },
		{ provide: HTTP_INTERCEPTORS, useClass: HandleJwtInterceptor, multi: true }
	]
})
export class CoreModule {
	constructor(
		ngRedux: NgRedux<IRootState>,
		devTools: DevToolsExtension
	) {
		const enhancers: StoreEnhancer<IRootState, {}>[] = [];
		const initialState = DEFAULT_ROOT_STATE;

		// load dev tools for non-production environments, when available and enabled
		if (!environment.production && devTools.isEnabled()) {
			enhancers.push(devTools.enhancer());
		}

		ngRedux.configureStore(rootReducer, initialState, undefined, enhancers);
	}
}
