import { Component, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ProfileComponentBase } from 'core/components/base/profile-component.base';
import { AppStateService } from 'core/services/state/app-state.service';
import { UIStateService } from 'core/services/state/ui-state.service';
import { IStorageService, StorageServiceToken } from 'core/services/storage/storage.service';
import { getObjectValue } from 'core/utility/data.utility';

import { ObsStatus } from 'integrations/obs/models/obs.models';
import { IObsService, obsServiceToken } from 'integrations/obs/services/obs.service';
import { TwitchIrcService } from 'integrations/twitch/services/twitchIrc.service';

import { IProfileModel } from 'channel/models/profile.models';
import { IViewModel } from 'channel/models/view.models';

import { ProfileManager } from 'profile/services/profile.manager';
import { ProfileService } from 'profile/services/profile.service';

@Component({
	selector: 'stream-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends ProfileComponentBase {
	// TODO: extend to channels for sys admins
	public profiles: IProfileModel[] = [];
	public selectedProfile: IProfileModel;

	public activeView: IViewModel;
	public selectedView: IViewModel;
	public isAwaitingTransition: boolean = false;

	public variants: string[] = [];
	public selectedVariants: string[] = [];
	public isAwaitingVariants: boolean = false;

	// obs
	public isObsConnected: boolean = false;
	private _obsPreDelay: number = 0;
	private _obsPostDelay: number = 0;

	// twitch
	public isChatEnabled: boolean = false;
	public isChatExpanded: boolean = false;

	constructor(
		appState: AppStateService,
		private _uiState: UIStateService,
		@Inject(StorageServiceToken) storage: IStorageService,
		private _profileManager: ProfileManager,
		private _profileService: ProfileService,
		@Inject(obsServiceToken) private _obs: IObsService,
		private _twitchIrc: TwitchIrcService
	) {
		super(appState, storage);
	}

	protected initSubscriptions(): Subscription[] {
		return [
			...super.initSubscriptions(),
			this.appState.watchProfiles()
				.subscribe((result) => {
					this.profiles = result || [];
				}),
			this.appState.watchActiveProfile()
				.subscribe((result) => {
					this.selectedProfile = result;
					this.selectedView = null;
					this.selectedVariants = [];
					this.isAwaitingVariants = false;
				}),
			this.appState.watchActiveView()
				.subscribe((result) => {
					this.activeView = result;
					this.loadVariants();
				}),
			this._twitchIrc.configStream
				.subscribe((result) => {
					this.isChatEnabled = (result !== null);
				}),
			this._obs.configStream
				.subscribe((result) => {
					this._obsPreDelay = getObjectValue<number>(result, 'transition.preDelay', 0);
					this._obsPostDelay = getObjectValue<number>(result, 'transition.postDelay', 0);
				}),
			this._obs.watchStatus()
				.subscribe((result) => {
					this.isObsConnected = (result === ObsStatus.CONNECTED);
				})
		];
	}

	public selectProfile(id: string): void {
		let profile: IProfileModel = null;

		if (id) {
			profile = this.profiles.find((x) => x.id === id);
		}
		else {
			// ...or the first profile, by default
			profile = this.profiles[0];
		}

		this._profileManager.load(profile);
	}

	public selectView(id: string): void {
		// only save previous view if one not already saved
		if (!this.activeView) {
			this.activeView = this.selectedView;
		}

		this.selectedView = this.profile.views.find((x) => x.id === id);
		this.selectedVariants = [];
		this.isAwaitingVariants = false;

		this.loadVariants();
	}

	public toggleVariant(id: string): void {
		const index = this.selectedVariants.indexOf(id);
		if (index > -1) {
			this.selectedVariants.splice(index, 1);
		}
		else {
			this.selectedVariants.push(id);
		}

		this.isAwaitingVariants = true;
	}

	public isVariantSelected(id: string): boolean {
		return this.selectedVariants.indexOf(id) > -1;
	}

	public startTransition(): void {
		this.isAwaitingTransition = true;

		if (this.isObsConnected) {
			const activeView = this.selectedView || this.activeView;
			this._obs.hideItemsBeforeTransition(activeView.name);
		}

		this._profileService.transition(this.channel.id, this.profile.id, [], this.selectedView.id, null, this.selectedVariants)
			.pipe(
				take(1)
			)
			.subscribe(() => {
				if (this.isObsConnected) {
					setTimeout(() => {
						this._obs.setScene(this.selectedView.name);
						this.completeTransition();
					}, this._obsPreDelay);
				}
			});
	}

	public completeTransition(): void {
		if (!this.isAwaitingTransition) { return; }

		const postDelay: number = this.isObsConnected ? this._obsPostDelay : 0;

		this._profileService.transition(this.channel.id, this.profile.id, [], this.selectedView.id, postDelay, this.selectedVariants)
			.pipe(
				take(1)
			)
			.subscribe(() => {
				if (this.isObsConnected) {
					this._obs.showItemsAfterTransition(this.selectedView.name);
				}

				this.appState.storeActiveView(this.selectedView.id);
				this.selectedView = null;
				this.isAwaitingTransition = false;
				this.isAwaitingVariants = false;
			});
	}

	public applyVariants(): void {
		this._profileService.applyVariants(this.channel.id, this.profile.id, [], this.selectedVariants)
			.pipe(
				take(1)
			)
			.subscribe(() => {
				this.isAwaitingVariants = false;
			});
	}

	public toggleChat(): void {
		this.isChatExpanded = !this.isChatExpanded;
		this._uiState.storeIsChatExpanded(this.isChatExpanded);
	}

	// #region Internal

	private loadVariants(): void {
		const fromView = this.selectedView || this.activeView;

		this.variants = getObjectValue<string[]>(fromView, 'variants', []);
	}

	// #endregion
}
