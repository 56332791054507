<div class="copyright">
	<i class="hover-target fas fa-copyright"></i>

	<div class="logo">
		Zero Hour Productions
	</div>

	<div class="terms">
		Copyright &copy; 2017-2019, all rights reserved
	</div>
</div>

<div class="version">
	Stream Admin, v1.5.3
</div>

<stream-obs-status *ngIf="isObsEnabled"></stream-obs-status>
