<div class="chat-frame">
	<iframe [src]="chatUrl" *ngIf="chatUrl"></iframe>
</div>

<div class="chat-as" *ngIf="chatIds.length > 0" [class.collapsed]="isChatAsCollapsed">
	<div class="chat-as-toggle">
		<button type="button" class="btn btn-primary" (click)="toggleChatAs()">
			<i class="fas" [class.fa-angle-double-up]="isChatAsCollapsed" [class.fa-angle-double-down]="!isChatAsCollapsed"></i>
		</button>
	</div>

	<div class="chat-as-title">Chat as - {{ chatStatus }}</div>

	<select class="form-control chat-as-select" [(ngModel)]="chatId" name="chatId">
		<option *ngFor="let chatId of chatIds" [value]="chatId.id">{{ chatId.name }}</option>
	</select>

	<div class="input-group">
		<input [(ngModel)]="chatContent" (keyup.enter)="chatAs()" class="form-control chat-as-content" name="chatContent" />
		<div class="input-group-append">
			<button class="btn btn-primary chat-as-send" type="button" (click)="chatAs()"><i class="fas fa-comment"></i></button>
		</div>
	</div>
</div>
