import { InjectionToken } from '@angular/core';
import { IWebsocketHandler, IWebsocketMessageModel, WEBSOCKET_MESSAGE_TYPE } from '../../models/websocket.models';

export const websocketServiceToken = new InjectionToken<IWebsocketService>('IWebsocketService');

export interface IWebsocketService {
	connect: () => void;
	disconnect: () => void;
	send: (model: IWebsocketMessageModel) => void;
	handle: (type: WEBSOCKET_MESSAGE_TYPE, handler: IWebsocketHandler<any>) => void;
}
