import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiBaseService } from 'core/services/api/api-base.service';

import { ISendStateRequestModel } from 'admin/models/admin-api.models';

@Injectable()
export class AdminApiService extends ApiBaseService {
	constructor(http: HttpClient) {
		super(http);
		this.baseUrl = 'admin';
	}

	public saveState(channel: string, profile: string, state: string): Observable<void> {
		return this.put<void>('/state', {
			channel: channel,
			profile: profile,
			state: state
		} as ISendStateRequestModel);
	}
}
