import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { createSelector } from 'reselect';
import { Observable } from 'rxjs';

import { APP_STATE_ACTIONS } from 'core/state/app-state.actions';
import { IAppState } from 'core/state/app-state.model';
import { getAppState, getRootState, IRootState } from 'core/state/root-state.model';
import { ISubstateAction } from 'core/state/state-actions';
import { StateBaseService } from './state-base.service';

import { IChannelModel } from 'channel/models/channel.models';
import { IProfileModel } from 'channel/models/profile.models';
import { IViewModel } from 'channel/models/view.models';
import { IFrontendInfoModel } from 'frontend/models/frontend.models';

const getActiveChannel = createSelector(getAppState, (state: IAppState): IChannelModel => state.channels.find((x) => x.id === state.activeChannel));

const getActiveProfile = createSelector(getAppState, getActiveChannel, (state: IAppState, channel: IChannelModel): IProfileModel => channel?.profiles.find((x) => x.id === state.activeProfile));

const getActiveView = createSelector(getAppState, getActiveProfile, (state: IAppState, profile: IProfileModel): IViewModel => profile?.views.find((x) => x.id === state.activeView));

const getChannels = createSelector(getAppState, (state: IAppState): IChannelModel[] => state.channels);

const getProfiles = createSelector(getActiveChannel, (channel: IChannelModel): IProfileModel[] => channel?.profiles);

const getViews = createSelector(getActiveProfile, (profile: IProfileModel): IViewModel[] => profile?.views);

const getFrontends = createSelector(getAppState, (state: IAppState): IFrontendInfoModel[] => state.frontends);

@Injectable()
export class AppStateService extends StateBaseService<ISubstateAction<APP_STATE_ACTIONS>> {
	protected getSubstate = createSelector(getRootState, getAppState);

	constructor(state: NgRedux<IRootState>) {
		super(state);
	}

	public storeChannels = (channels: IChannelModel[]): void =>
		this.dispatch({
			type: APP_STATE_ACTIONS.STORE_CHANNELS,
			payload: channels
		})

	public watchChannels = (): Observable<IChannelModel[]> => this.select(getChannels);

	public storeActiveChannel = (id: string): void => {
		this.dispatch({
			type: APP_STATE_ACTIONS.STORE_ACTIVE_CHANNEL,
			payload: id
		});
	}

	public watchActiveChannel = (): Observable<IChannelModel> => this.select(getActiveChannel);

	public watchProfiles = (): Observable<IProfileModel[]> => this.select(getProfiles);

	public storeActiveProfile = (id: string): void =>
		this.dispatch({
			type: APP_STATE_ACTIONS.STORE_ACTIVE_PROFILE,
			payload: id
		})

	public watchActiveProfile = (): Observable<IProfileModel> => this.select(getActiveProfile);

	public storeActiveView = (id: string): void => {
		this.dispatch({
			type: APP_STATE_ACTIONS.STORE_ACTIVE_VIEW,
			payload: id
		});
	}

	public watchActiveView = (): Observable<IViewModel> => this.select(getActiveView);

	public watchAppState = (): Observable<IAppState> => this.select(getAppState);
}
