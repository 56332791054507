<table class="table table-bordered" *ngIf="schedule">
	<caption>
		Last update: {{ schedule.lastUpdate }}
	</caption>
	<thead>
		<tr>
			<th>Description</th>
			<th>Estimate</th>
			<th>Runners</th>
			<th class="actions"></th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let item of schedule.schedule">
			<td>{{ item.gameName }}</td>
			<td>{{ item.expectedDuration }}</td>
			<td>{{ item.runners }}</td>
			<td>
				<button type="button" class="btn btn-success btn-sm" (click)="add(item)" title="Add to outgoing schedule">
					<i class="fas fa-plus"></i>
				</button>
			</td>
		</tr>
	</tbody>
</table>
