import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { IAuthRequestModel, IAuthResponseModel } from '../../models/auth.models';
import { ApiBaseService } from '../api/api-base.service';
import { AuthManagerService } from './auth-manager.service';

@Injectable()
export class AuthService extends ApiBaseService {
	constructor(
		private _router: Router,
		private _authManager: AuthManagerService,
		http: HttpClient
	) {
		super(http);
		this.baseUrl = 'auth';
	}

	public check(): Observable<boolean> {
		return this.post<void>('/check', null)
			.pipe(
				map(() => true),
				catchError(() => {
					return of(false);
				})
			);
	}

	public login(model: IAuthRequestModel): Observable<IAuthResponseModel> {
		return this.post<IAuthResponseModel>('/login', model);
	}

	public logout(): void {
		this._authManager.clearAuthentication();

		this._router.navigate(['/login']);
	}
}
