import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { ObservableComponentBase } from 'core/components/base/observable-component.base';
import { ITwitchChatUserModel, TwitchChatStatus } from 'integrations/twitch/models/twitch.models';
import { TwitchIrcService } from 'integrations/twitch/services/twitchIrc.service';

@Component({
	selector: 'stream-twitch-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss']
})
export class TwitchChatAsComponent extends ObservableComponentBase {
	public chatIds: ITwitchChatUserModel[] = [];
	public chatId: string = '';
	public chatContent: string = '';
	public chatStatus: TwitchChatStatus = TwitchChatStatus.DISCONNECTED;
	public chatUrl: SafeResourceUrl;
	public isChatAsCollapsed: boolean = false;

	constructor(
		private _twitchIrc: TwitchIrcService,
		private _sanitizer: DomSanitizer
	) {
		super();
	}

	protected initSubscriptions(): Subscription[] {
		return [
			this._twitchIrc.configStream
				.subscribe((config) => {
					if (!config) {
						this.chatIds = [];
						this.chatId = '';
						this.chatUrl = null;
						return;
					}

					if (config.channel) {
						this.chatIds = config.chatAs.map((x) => {
							return {
								id: x.id,
								name: x.name,
								token: null
							};
						});

						if (this.chatIds.length > 0) {
							this.chatId = this.chatIds[0].id;
						}

						this.chatUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`https://twitch.tv/embed/${config.channel}/chat`);
					}
				}),
			this._twitchIrc.getStatus()
				.subscribe((result) => {
					this.chatStatus = result;
				})
		];
	}

	public chatAs(): void {
		this._twitchIrc.chatAs(this.chatId, this.chatContent).subscribe();
		this.chatContent = '';
	}

	public toggleChatAs(): void {
		this.isChatAsCollapsed = !this.isChatAsCollapsed;
	}
}
