<div *ngIf="!challenges || !challenges.length">
	No challenges available
</div>

<table class="table table-bordered" [class.empty]="!challenges || !challenges.length">
	<thead>
		<tr>
			<th>Description</th>
			<th>Count</th>
			<th>Amount Per</th>
			<th>Total</th>
			<th>Active?</th>
			<th class="actions"></th>
		</tr>
	</thead>

	<tbody>
		<tr *ngFor="let item of challenges" [class.deleted]="item._delete">
			<td>
				<input type="text" [name]="'description' + item.id" class="form-control" [(ngModel)]="item.description" />
			</td>
			<td>
				<div class="input-group">
					<input type="number" [name]="'count' + item.id" min="0" step="1" class="form-control" [(ngModel)]="item.count" />

					<div class="input-group-append">
						<button type="button" class="btn btn-default" title="Decrement" (click)="decrement(item)">
							<i class="fas fa-minus"></i>
						</button>
						<button type="button" class="btn btn-default" title="Increment" (click)="increment(item)">
							<i class="fas fa-plus"></i>
						</button>
					</div>
				</div>
			</td>
			<td>
				<input type="number" [name]="'amount' + item.id" min="0" step="0.01" class="form-control" [(ngModel)]="item.amount" />
			</td>
			<td>
				{{ formatTotal(item) }}
			</td>
			<td class="flags">
				<input type="checkbox" [name]="'isActive' + item.id" [(ngModel)]="item.isActive" />
			</td>
			<td class="actions">
				<button type="button" class="btn btn-danger" title="Remove challenge" (click)="remove(item)" *ngIf="!item._delete">
					<i class="fas fa-times"></i>
				</button>
				<button class="btn btn-default undelete" title="Undo remove" (click)="remove(item)" *ngIf="item._delete === true">
					<i class="fas fa-undo"></i>
				</button>
			</td>
		</tr>
	</tbody>
</table>
