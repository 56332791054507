<table class="table table-bordered">
	<thead>
		<tr>
			<th>Name</th>
			<th>State</th>
			<th>Run Frequency</th>
			<th>Last Run Date</th>
			<th class="actions"></th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let command of commands">
			<td>{{ command.name }}</td>
			<td>{{ getCommandState(command) }}</td>
			<td>{{ getCommandFrequency(command) }}</td>
			<td>{{ formatDate(command.lastRunDate) }}</td>
			<td class="actions">
				<div class="btn-group">
					<button type="button" class="btn btn-success btn-sm" (click)="start(command)" title="Start" [disabled]="!canStart(command)">
						<i class="fas fa-play"></i>
					</button>
					<button type="button" class="btn btn-danger btn-sm" (click)="stop(command)" title="Stop" [disabled]="!canStop(command)">
						<i class="fas fa-stop"></i>
					</button>
				</div>
			</td>
		</tr>
	</tbody>
</table>
