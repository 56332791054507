import { Reducer } from 'redux';

import { ISubstateAction } from './state-actions';
import { UI_STATE_ACTIONS } from './ui-state.actions';
import { DEFAULT_UI_STATE, IUIState } from './ui-state.models';

export const uiReducer: Reducer<IUIState, ISubstateAction<UI_STATE_ACTIONS>> = (state: IUIState = DEFAULT_UI_STATE, action: ISubstateAction<UI_STATE_ACTIONS>): IUIState => {
	switch (action.type) {
		case UI_STATE_ACTIONS.STORE_CHAT_EXPAND:
			return { ...state, isChatExpanded: action.payload };
	}

	return state;
};
