<h1>Manage Data</h1>

<div class="data-actions">
	<button type="button" class="btn btn-danger" (click)="clearDataState()">
		Clear data state
		<i class="fas fa-times"></i>
	</button>

	<button type="button" class="btn btn-danger" (click)="clearFrontendState()">
		Clear frontend state
		<i class="fas fa-times"></i>
	</button>
</div>

<div class="row">
	<div class="col-md-4">
		<h2>App State</h2>

		<pre [innerText]="(currentAppState | async) | json"></pre>
	</div>

	<div class="col-md-4">
		<h2>Data State</h2>

		<pre [innerText]="(currentDataState | async) | json"></pre>
	</div>

	<div class="col-md-4">
		<h2>UI State</h2>

		<pre [innerText]="(currentUIState | async) | json"></pre>
	</div>
</div>
