<h1>Manage Elements</h1>

<div class="views">
	<div class="views-active">
		<h2>Active view</h2>

		<stream-element-view [model]="activeView | async" (elementAction)="doElementAction($event)"></stream-element-view>
	</div>

	<div class="views-other">
		<h2>Other views</h2>

		<stream-element-view *ngFor="let view of (otherViews | async)" [model]="view" [isCollapsed]="true" (elementAction)="doElementAction($event)"></stream-element-view>
	</div>
</div>
