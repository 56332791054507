import { Component, Inject } from '@angular/core';
import { Subscription } from 'rxjs';

import { ObservableComponentBase } from '../base/observable-component.base';

import { IObsService, obsServiceToken } from 'integrations/obs/services/obs.service';

@Component({
	selector: 'stream-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends ObservableComponentBase {
	public isObsEnabled: boolean = false;

	constructor(@Inject(obsServiceToken) private _obs: IObsService) {
		super();
	}

	protected initSubscriptions(): Subscription[] {
		return [
			this._obs.configStream
				.subscribe((result) => {
					this.isObsEnabled = (result !== null);
				})
		];
	}
}
