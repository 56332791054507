import { Component } from '@angular/core';
import { ElementBaseComponent } from 'element/components/element-base/element-base.component';
import { ITextElementDataModel } from 'element/models/element.models';

@Component({
	selector: 'stream-text-element',
	templateUrl: './text-element.component.html',
	styleUrls: ['./text-element.component.scss']
})
export class TextElementComponent extends ElementBaseComponent<ITextElementDataModel> { }
