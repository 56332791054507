import { Inject, Injectable } from '@angular/core';

import { AppStateService } from 'core/services/state/app-state.service';
import { RootStateService } from 'core/services/state/root-state.service';
import { IStorageService, StorageServiceToken } from 'core/services/storage/storage.service';
import { getObjectValue, safeAtoBJson } from 'core/utility/data.utility';

import { IProfileModel } from 'channel/models/profile.models';

import { IRootState } from 'core/state/root-state.model';
import { IObsConfigModel } from 'integrations/obs/models/obs.models';
import { IObsService, obsServiceToken } from 'integrations/obs/services/obs.service';
import { ITwitchConfigModel } from 'integrations/twitch/models/twitch.models';
import { TwitchIrcService } from 'integrations/twitch/services/twitchIrc.service';

@Injectable()
export class ProfileManager {
	constructor(
		@Inject(obsServiceToken) private _obs: IObsService,
		private _twitchIrc: TwitchIrcService,
		private _appState: AppStateService,
		private _rootState: RootStateService,
		@Inject(StorageServiceToken) private _storage: IStorageService,
	) { }

	public load(profile: IProfileModel): void {
		this._obs.unload();

		const obsConfig = getObjectValue<IObsConfigModel>(profile, 'config.obs');
		if (obsConfig && this._obs.load(obsConfig)) {
			// attempt initial connection
			this._obs.connect().subscribe();
		}

		this._twitchIrc.unload();

		const twitchConfig = getObjectValue<ITwitchConfigModel>(profile, 'config.twitch');
		if (twitchConfig && this._twitchIrc.load(twitchConfig)) {
			// attempt initial connection
			this._twitchIrc.connect().subscribe();
		}

		// TODO: default configurable at profile level?
		let activeView = profile.views[0].id;

		// get incoming state, if any
		const data = safeAtoBJson<IRootState>(profile.data);
		if (data) {
			const stateViewId = getObjectValue(data, 'app.activeView');
			const stateProfile = profile.views.find((x) => x.id === stateViewId);

			activeView = stateProfile ? stateProfile.id : activeView;

			this._rootState.importSavedState({
				data: data.data
			});
		}

		this._appState.storeActiveProfile(profile.id);
		this._appState.storeActiveView(activeView);
		this._storage.setValue('activeProfile', profile.id || null);
	}
}
