import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IScheduleDataModel } from 'core/models/data.models';

import { IScheduleUpdate } from '../../models/schedule.models';

import { IScheduleIncomingListAction, SCHEDULE_INCOMING_LIST_ACTION_TYPE } from '../../utility/incoming-schedule-list.action';

@Component({
	selector: 'stream-schedule-incoming-list',
	templateUrl: './schedule-incoming-list.component.html'
})
export class ScheduleIncomingListComponent {
	@Input()
	public schedule: IScheduleUpdate = {} as IScheduleUpdate;

	@Output()
	public listAction = new EventEmitter<IScheduleIncomingListAction>();

	public add(item: IScheduleDataModel): void {
		this.listAction.emit({
			type: SCHEDULE_INCOMING_LIST_ACTION_TYPE.Add,
			item: item
		} as IScheduleIncomingListAction);
	}
}
