<h1>Manage People</h1>

<div class="row">
	<div class="col-md-6">
		<div class="actions-container">
			<div class="left">
				<button type="button" class="btn btn-primary" (click)="getPeople()">
					Reload people
					<i class="fas fa-sync"></i>
				</button>
			</div>
		</div>

		<stream-people-list [people]="people | async" (listAction)="doListAction($event)"></stream-people-list>
	</div>

	<div class="col-md-6">
		<div class="actions-container">
			<div class="right">
				<button type="button" class="btn btn-primary" (click)="sendPlayers()">
					Update players
					<i class="fas fa-share"></i>
				</button>
			</div>
		</div>

		<stream-player-list [players]="currentPlayers"></stream-player-list>
	</div>
</div>
