import { Component, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { ProfileComponentBase } from 'core/components/base/profile-component.base';

import { WEBSOCKET_STATE_TYPE } from 'core/models/websocket.models';
import { AppStateService } from 'core/services/state/app-state.service';
import { IStorageService, StorageServiceToken } from 'core/services/storage/storage.service';

import { IFrontendInfoModel } from '../../models/frontend.models';
import { FrontendService } from '../../services/frontend.service';
import { FRONTEND_LIST_ACTION, IFrontendListAction } from '../../utility/frontend-list.action';

@Component({
	selector: 'stream-frontend-admin',
	templateUrl: './frontend-admin.component.html'
})
export class FrontendAdminComponent extends ProfileComponentBase {
	public frontends: Observable<IFrontendInfoModel[]>;

	private _getFrontends = new Subject<void>();

	constructor(
		appState: AppStateService,
		@Inject(StorageServiceToken) storage: IStorageService,
		private _frontendService: FrontendService,
	) {
		super(appState, storage);

		this.whenProfileSettled = this.getFrontends;
	}

	protected initObservables(): Observable<any>[] {
		return [
			...super.initObservables(),
			this.frontends = this._getFrontends
				.pipe(
					switchMap(() => {
						return this._frontendService.getList(this.profile.channel, this.profile.id);
					})
				)
		];
	}

	public getFrontends(): void {
		this._getFrontends.next();
	}

	public doListAction(action: IFrontendListAction) {
		switch (action.action) {
			case FRONTEND_LIST_ACTION.Authorize:
				this.authorize(action.frontend);
				break;
			case FRONTEND_LIST_ACTION.Disconnect:
				this.disconnect(action.frontend);
				break;
		}
	}

	// #region Internal

	private authorize(frontend: IFrontendInfoModel): void {
		this._frontendService.authorize(frontend.id, frontend.channel, frontend.profile)
			.pipe(
				take(1)
			)
			.subscribe(() => {
				frontend.connection.isAuthorized = true;
			});
	}

	private disconnect(frontend: IFrontendInfoModel): void {
		this._frontendService.disconnect(frontend.id)
			.pipe(
				take(1)
			)
			.subscribe(() => {
				frontend.connection.isAuthorized = false;
				frontend.connection.state = WEBSOCKET_STATE_TYPE.Closed;
			});
	}

	// #endregion
}
