import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CommandAdminComponent } from './components/command-admin/command-admin.component';
import { CommandListComponent } from './components/command-list/command-list.component';

import { CommandService } from './services/command.service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
	],
	declarations: [
		CommandAdminComponent,
		CommandListComponent
	],
	providers: [
		CommandService
	],
	exports: []
})
export class CommandModule { }
