import { Component, Inject } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { combineLatest, delay, map, switchMap, take } from 'rxjs/operators';
import * as uuid from 'uuid';

import { ProfileComponentBase } from 'core/components/base/profile-component.base';
import { IBidDataModel, IChallengeDataModel, IDeleteable, IDonationDataModel, IMilestoneDataModel } from 'core/models/data.models';

import { AppStateService } from 'core/services/state/app-state.service';
import { IStorageService, StorageServiceToken } from 'core/services/storage/storage.service';

import { IDonationsUpdate } from '../../models/donations.models';
import { DonationsService } from '../../services/donations.service';

import { DataStateService } from 'core/services/state/data-state.service';

import { BIDS_ACTION_TYPE, IBidsAction } from 'donation/utility/bids-action';
import { CHALLENGES_ACTION_TYPE, IChallengesAction } from 'donation/utility/challenges-action';
import { IMilestonesAction, MILESTONES_ACTION_TYPE } from 'donation/utility/milestones-action';
import { DONATIONS_LIST_ACTION_TYPE, IDonationsListAction } from '../../utility/donations-list.action';

@Component({
	selector: 'stream-donations-admin',
	templateUrl: './donations-admin.component.html',
	styleUrls: ['./donations-admin.component.scss']
})
export class DonationsAdminComponent extends ProfileComponentBase {
	public sourceIds: string[] = null;
	public selectedSourceId: string = null;
	public hasSources: boolean = false;

	public donationTotal: number;
	public donations: Observable<IDonationsUpdate>;
	public milestones: IMilestoneDataModel[] = [];
	public bids: IBidDataModel[] = [];
	public challenges: IChallengeDataModel[] = [];

	public isMilestonesCollapsed: boolean = false;
	public isBidsCollapsed: boolean = false;
	public isChallengesCollapsed: boolean = false;

	private _donationSourceId = new Subject<string>();
	private _sourcesLoaded = new Subject<void>();

	constructor(
		appState: AppStateService,
		@Inject(StorageServiceToken) storage: IStorageService,
		private _dataState: DataStateService,
		private _donationsService: DonationsService
	) {
		super(appState, storage);
	}

	protected initObservables(): Observable<any>[] {
		return [
			...super.initObservables(),
			this.donations = this._dataState.watchDonations()
				.pipe(
					combineLatest(this._donationSourceId),
					map(([sources, selectedSourceId]) => {
						this.selectedSourceId = selectedSourceId;

						if (!this.selectedSourceId || !sources[this.selectedSourceId]) {
							return;
						}

						// TODO: better sorting generics
						return {
							lastUpdate: moment.utc().toDate(),
							sourceId: this.selectedSourceId,
							donations: sources[this.selectedSourceId].sort((a, b) => {
								if (!a || !b) { return 0; }

								switch (true) {
									case a.date < b.date:
										return 1;
									case a.date > b.date:
										return -1;
								}

								return 0;
							})
						} as IDonationsUpdate;
					})
				)
		];
	}

	protected initSubscriptions(): Subscription[] {
		return [
			...super.initSubscriptions(),
			this._sourcesLoaded
				.pipe(
					delay(0),
					take(1)
				)
				.subscribe(() => {
					const sourceId = this.sourceIds ? this.sourceIds[0] : null;

					if (sourceId) {
						this.selectSource(sourceId);
					}
				}),
			this._dataState.watchDonations()
				.subscribe((sources) => {
					this.sourceIds = sources ? Object.keys(sources) : [];
					this.hasSources = this.sourceIds.length > 0;

					// (re)load selected donation source on data updates
					if (this.hasSources &&
						this.selectedSourceId) {
						this.selectSource(this.selectedSourceId);
					}

					// signal that sources are loaded
					this._sourcesLoaded.next();
				}),
			this._dataState.watchDonationTotals()
				.subscribe((result) => {
					// TODO: coalesce overall donation total somewhere else
					let total: number = 0;

					if (result) {
						for (const key of Object.keys(result)) {
							total += result[key].total;
						}
					}

					this.donationTotal = total;
				}),
			// TODO: revisit observable vs plain array: https://trello.com/c/Omt9Lx82
			this._dataState.watchMilestones()
				.subscribe((result) => {
					this.milestones = result;
				}),
			this._dataState.watchBids()
				.subscribe((result) => {
					this.bids = result;
				}),
			this._dataState.watchChallenges()
				.subscribe((result) => {
					this.challenges = result;
				})
		];
	}

	// #region Donations

	public selectSource(sourceId: string): void {
		this._donationSourceId.next(sourceId);
	}

	public doDonationAction(action: IDonationsListAction): void {
		switch (action.type) {
			case DONATIONS_LIST_ACTION_TYPE.MarkDone:
				this.saveDonation(action.sourceId, action.item);
				break;
		}
	}

	// #endregion

	// #region Milestones

	public addMilestone(): void {
		this.milestones.push({
			id: uuid.v4(),
			amount: 0,
			useTotal: false,
			description: 'New milestone',
			goal: 0,
			isActive: false
		});
	}

	public sendMilestones(): void {
		this._dataState.storeMilestones(this.milestones);

		this._donationsService.sendMilestones(this.channel.id, this.profile.id, this.milestones)
			.pipe(
				take(1)
			)
			.subscribe();
	}

	public doMilestoneAction(action: IMilestonesAction): void {
		switch (action.type) {
			case MILESTONES_ACTION_TYPE.Remove:
				this.toggleDeleted(this.milestones, (x) => x.id === action.item.id);
				break;
		}
	}

	public toggleMilestones(): void {
		this.isMilestonesCollapsed = !this.isMilestonesCollapsed;
	}

	// #endregion

	// #region Bids

	public addBidGroup(): void {
		this.bids.push({
			id: uuid.v4(),
			description: 'New bid group',
			options: [{
				id: uuid.v4(),
				description: 'New bid item',
				amount: 0
			}],
			isActive: false
		});
	}

	public sendBids(): void {
		this._dataState.storeBids(this.bids);

		this._donationsService.sendBids(this.channel.id, this.profile.id, this.bids)
			.pipe(
				take(1)
			)
			.subscribe();
	}

	public doBidAction(action: IBidsAction): void {
		switch (action.type) {
			case BIDS_ACTION_TYPE.RemoveGroup:
				this.toggleDeleted(this.bids, (x) => x.id === action.item.id);
				break;

			case BIDS_ACTION_TYPE.AddOption:
				this.addBidOption(action.item);
				break;

			case BIDS_ACTION_TYPE.RemoveOption:
				this.removeBidOption(action.item.id, action.optionId);
				break;
		}
	}

	public toggleBids(): void {
		this.isBidsCollapsed = !this.isBidsCollapsed;
	}

	// #endregion Bids

	// #region Challenges

	public addChallenge(): void {
		this.challenges.push({
			id: uuid.v4(),
			description: 'New challenge',
			count: 0,
			amount: 0,
			isActive: false
		});
	}

	public sendChallenges(): void {
		this._dataState.storeChallenges(this.challenges);

		this._donationsService.sendChallenges(this.channel.id, this.profile.id, this.challenges)
			.pipe(
				take(1)
			)
			.subscribe();
	}

	public doChallengeAction(action: IChallengesAction): void {
		switch (action.type) {
			case CHALLENGES_ACTION_TYPE.Remove:
				this.toggleDeleted(this.challenges, (x) => x.id === action.item.id);
				break;
		}
	}

	public toggleChallenges(): void {
		this.isChallengesCollapsed = !this.isChallengesCollapsed;
	}

	// #endregion

	// #region Internal

	private toggleDeleted<T extends IDeleteable>(source: T[], locator: (x: T) => boolean): void {
		const item = source.find(locator);
		if (item) {
			// ensure correct truthy/falsy comparison
			item._delete = (item._delete === true ? false : true);
		}
	}

	private addBidOption(group: IBidDataModel): void {
		group.options.push({
			id: uuid.v4(),
			description: 'New bid item',
			amount: 0
		});

		this._dataState.storeBids([group]);
	}

	private removeBidOption(bidId: string, itemId: string): void {
		const bid = this.bids.find((x) => x.id === bidId);
		if (!bid) { return; }

		this.toggleDeleted(bid.options, (x) => x.id === itemId);
	}

	private saveDonation(sourceId: string, item: IDonationDataModel): void {
		this._dataState.storeDonationChange(item, sourceId);

		// TODO: reinstate sending donations to other admins / frontends
		// this.donationsService.sendDonation(this.channel.id, this.profile.id, sourceId, item)
		// 	.pipe(
		// 		take(1)
		// 	)
		// 	.subscribe();
	}

	// #endregion
}
