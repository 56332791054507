import { Component, Inject } from '@angular/core';
import { Subscription } from 'rxjs';

import { ProfileComponentBase } from 'core/components/base/profile-component.base';
import { IScheduleDataModel } from 'core/models/data.models';
import { AppStateService } from 'core/services/state/app-state.service';
import { DataStateService } from 'core/services/state/data-state.service';
import { IStorageService, StorageServiceToken } from 'core/services/storage/storage.service';
import { IPersonInfoModel } from 'people/models/person.models';

import { deepCopy, getObjectValue } from 'core/utility/data.utility';
import { formatCurrency } from 'core/utility/format.utility';
import { ITwitterTweetTemplateModel } from 'social/models/twitter.models';

@Component({
	selector: 'stream-social-admin',
	templateUrl: './social-admin.component.html'
})
export class SocialAdminComponent extends ProfileComponentBase {
	public currentPlayers: IPersonInfoModel[] = [];
	public currentGame: IScheduleDataModel = {} as IScheduleDataModel;
	public donationTotal: number = 0;

	public tweetTemplates: ITwitterTweetTemplateModel[] = [];
	public tweetText: string = '';

	constructor(
		appState: AppStateService,
		@Inject(StorageServiceToken) storage: IStorageService,
		private _dataState: DataStateService
	) {
		super(appState, storage);

		this.whenProfileSettled = () => {
			this.tweetTemplates = getObjectValue<ITwitterTweetTemplateModel[]>(this.profile.config, 'twitter.templates', []);
		};
	}

	public initSubscriptions(): Subscription[] {
		return [
			...super.initSubscriptions(),
			this._dataState.watchCurrentPlayers()
				.subscribe((result) => {
					if (result) {
						this.currentPlayers = result;
					}
				}),
			this._dataState.watchOutgoingSchedule()
				.subscribe((result) => {
					if (result) {
						this.currentGame = result[0] || ({} as IScheduleDataModel);
					}
				}),
			this._dataState.watchDonationTotals()
				.subscribe((result) => {
					if (result) {
						this.donationTotal = 0;

						for (const key of Object.keys(result)) {
							this.donationTotal += result[key].total;
						}
					}
				})
		];
	}

	public generateTweet(id: string): void {
		const template = this.tweetTemplates.find((x) => x.id === id);
		if (!template) { return; }

		let playerString: string = '';
		const players = deepCopy(this.currentPlayers)
			.filter((x) => x.alias);
		const lastPlayer = players.pop();

		if (!players.length) {
			playerString = lastPlayer.alias;
		}
		else {
			const playerAliases = players.map((x) => x.alias);

			playerString = [playerAliases.join(', '), lastPlayer.alias].join(', and ');
		}

		// TODO: parameterize event hash, Twitch channel, other markers
		this.tweetText = template.template
			.replace('{{player}}', playerString)
			.replace('{{currentGameName}}', this.currentGame.gameName)
			.replace('{{currentGameCategory}}', this.currentGame.runCategory)
			.replace('{{donationTotal}}', formatCurrency(this.donationTotal));
	}

	public selectTweet(event: Event): void {
		(event.target as HTMLTextAreaElement).select();
	}
}
