import { NgModule } from '@angular/core';

import { ChannelService } from './services/channel.service';

@NgModule({
	declarations: [],
	providers: [
		ChannelService
	]
})
export class ChannelModule { }
