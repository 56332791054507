import { Component, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { ProfileComponentBase } from 'core/components/base/profile-component.base';

import { AppStateService } from 'core/services/state/app-state.service';

import { IStorageService, StorageServiceToken } from 'core/services/storage/storage.service';
import { COMMAND_STATE, ICommandInfoModel } from '../../models/command.models';
import { CommandService } from '../../services/command.service';
import { COMMAND_LIST_ACTION, ICommandListAction } from '../../utility/command-list.action';

@Component({
	selector: 'stream-command-admin',
	templateUrl: './command-admin.component.html'
})
export class CommandAdminComponent extends ProfileComponentBase {
	public commands: Observable<ICommandInfoModel[]>;

	private _getCommands = new Subject<void>();

	constructor(
		appState: AppStateService,
		@Inject(StorageServiceToken) storage: IStorageService,
		private _commandService: CommandService
	) {
		super(appState, storage);

		this.whenProfileSettled = this.getCommands;
	}

	protected initObservables(): Observable<any>[] {
		return [
			...super.initObservables(),
			this.commands = this._getCommands
				.pipe(
					switchMap(() => {
						return this._commandService.getCommands(this.channel.id, this.profile.id);
					})
				)
		];
	}

	public getCommands(): void {
		this._getCommands.next();
	}

	public doListAction(action: ICommandListAction) {
		switch (action.action) {
			case COMMAND_LIST_ACTION.Start:
				this.start(action.command);
				break;
			case COMMAND_LIST_ACTION.Stop:
				this.stop(action.command);
				break;
		}
	}

	// #region Internal

	private start(command: ICommandInfoModel): void {
		this._commandService.start(command.channel, command.profile, command.id)
			.pipe(
				take(1)
			)
			.subscribe(() => {
				command.state = COMMAND_STATE.Waiting;
			});
	}

	private stop(command: ICommandInfoModel): void {
		this._commandService.stop(command.channel, command.profile, command.id)
			.pipe(
				take(1)
			)
			.subscribe(() => {
				command.state = COMMAND_STATE.Stopped;
			});
	}

	// #endregion
}
