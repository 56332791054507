import { Component, Inject } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ProfileComponentBase } from 'core/components/base/profile-component.base';
import { AppStateService } from 'core/services/state/app-state.service';
import { DataStateService } from 'core/services/state/data-state.service';
import { IStorageService, StorageServiceToken } from 'core/services/storage/storage.service';

import { IPersonInfoModel } from 'people/models/person.models';
import { PersonService } from 'people/services/person.service';
import { IPeopleListAction, PEOPLE_LIST_ACTION_TYPE } from 'people/utility/people-list.action';

import { deepCopy } from 'core/utility/data.utility';

@Component({
	selector: 'stream-people-admin',
	templateUrl: './people-admin.component.html'
})
export class PeopleAdminComponent extends ProfileComponentBase {
	public people: Observable<IPersonInfoModel[]>;
	public currentPlayers: IPersonInfoModel[] = [];

	constructor(
		appState: AppStateService,
		@Inject(StorageServiceToken) storage: IStorageService,
		private _dataState: DataStateService,
		private _personService: PersonService
	) {
		super(appState, storage);

		this.whenProfileSettled = this.getPeople;
	}

	protected initObservables(): Observable<any>[] {
		return [
			...super.initObservables(),
			this.people = this._dataState.watchPeople()
		];
	}

	protected initSubscriptions(): Subscription[] {
		return [
			...super.initSubscriptions(),
			this._dataState.watchCurrentPlayers()
				.subscribe((result) => {
					this.currentPlayers = result;
				})
		];
	}

	public doListAction(action: IPeopleListAction): void {
		switch (action.type) {
			case PEOPLE_LIST_ACTION_TYPE.SetPlayer:
				this.setPlayer(action.person, action.index);
				break;
		}
	}

	public getPeople(): void {
		this._personService.getList(this.channel.id, this.profile.id)
			.pipe(
				take(1)
			)
			.subscribe((people) => {
				this._dataState.storePeople(people);
			});
	}

	public setPlayer(person: IPersonInfoModel, index: number): void {
		this.currentPlayers[index] = deepCopy(person);

		// this._dataState.storeCurrentPlayers(players);
	}

	public sendPlayers(): void {
		this._dataState.storeCurrentPlayers(this.currentPlayers);

		this._personService.sendPlayers(this.channel.id, this.profile.id, this.currentPlayers)
			.pipe(
				take(1)
			)
			.subscribe();
	}
}
