import { Component, Inject } from '@angular/core';
import { Subscription } from 'rxjs';

import { ObservableComponentBase } from 'core/components/base/observable-component.base';

import { ObsStatus } from '../../models/obs.models';
import { IObsService, obsServiceToken } from '../../services/obs.service';

@Component({
	selector: 'stream-obs-status',
	templateUrl: './obs-status.component.html',
	styleUrls: ['./obs-status.component.scss']
})
export class ObsStatusComponent extends ObservableComponentBase {
	public status: ObsStatus;

	constructor(
		@Inject(obsServiceToken) private _obs: IObsService
	) {
		super();
	}

	protected initSubscriptions(): Subscription[] {
		return [
			this._obs.watchStatus()
				.subscribe((result) => {
					this.status = result;
				})
		];
	}

	public connect(): void {
		this._obs.connect().subscribe();
	}

	public disconnect(): void {
		this._obs.disconnect();
	}
}
